import { ImageProps } from "rc-image";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../../constants";

export const NavbarContainer = styled.nav`
    height: 70px;
    background-color: #000;
    position: sticky;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    padding: 8px 50px;
    flex-wrap: wrap;
`;

export const NavbarNavigationList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 20px;
`;

export const NavbarNavigationItem = styled.li`
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    margin-right: 25px;
    a {
        text-decoration: none;
    }

    a:hover,
    a:active {
        color: ${Colors.TA_GREEN};
    }
`;

const _NavbarLogo: React.FC<ImageProps> = ({ src, className }) => (
    <div className={className}>
        <img src={src} alt="logo" />
    </div>
);

export const NavbarLogo = styled(_NavbarLogo)``;
