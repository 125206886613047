import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const AgendaSectionContainer = styled.section`
    background-color: #000;
    width: 100%;
    margin: 0;
    position: relative;
    padding: 40px 0px 60px 0px;

    h2 {
        margin-left: 20px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        font-size: 2rem;
        margin-bottom: 100px;
        padding: 60px 120px;
        min-height: 500px;
    }
`;
