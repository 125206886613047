import { HTMLAttributes } from "react";
import { AgendaItemContainer } from "./styles/agenda-card";
import { IAgendaItem } from "../../types";
import { Col, Divider, Row } from "antd";
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import { FaSquareXTwitter } from "react-icons/fa6";
import dayjs from "dayjs";
import { Colors } from "../../constants";

export const AgendaCard: React.FC<HTMLAttributes<HTMLDivElement> & IAgendaItem> = ({
    id,
    description,
    speaker,
    startTime,
    endTime
}) => {
    return (
        <Row className="mb-3 d-flex ">
            <Col xs={24} xxl={3} className="mb-4 mb-md-0">
                <img src={speaker.photo} style={{ width: "10rem", height: "10rem", objectFit: "cover" }} />
            </Col>
            <Col xs={24} xxl={6}>
                <Row>
                    <h3 style={{ color: "#fff" }} className="mb-4">
                        {speaker.name}
                    </h3>
                </Row>
                <Row className="mb-3">
                    <p>{speaker.brief}</p>
                </Row>
                <Row className="d-flex align-items-center mb-4 mb-md-0">
                    <AiFillFacebook size={35} color="#fff" />
                    <AiFillLinkedin size={35} color="#fff" />
                    <FaSquareXTwitter size={32} color="#fff" />
                    <AiFillInstagram size={39} color="#fff" />
                </Row>
            </Col>
            <Col xs={24} xxl={2}></Col>
            <Col xs={24} xxl={8} className="mb-4 mb-md-0">
                <p>{description}</p>
            </Col>
            <Col xs={24} xxl={2}></Col>
            <Col xs={24} xxl={3} className="d-flex flex-column align-items-end">
                <h3 style={{ color: Colors.TA_GREEN }} className="mb-3">
                    {dayjs(startTime).format("DD MMM")}
                </h3>
                <div className="d-flex ">
                    <h3 style={{ color: Colors.TA_GREEN }}>{dayjs(startTime).format("HH:mm")}</h3>&nbsp;
                    <h3 style={{ color: Colors.TA_GREEN }}>-</h3>
                    &nbsp;
                    <h3 style={{ color: Colors.TA_GREEN }}>{dayjs(endTime).format("HH:mm")}</h3>
                </div>
            </Col>
            <Divider />
        </Row>
    );
};
