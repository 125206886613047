import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { GlobalStyles } from "./global-styles";
import { HomePage, RegisterPage } from "./pages";
import { CompleteLayout } from "./layouts/complete.layout";
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 1000 * 60
        }
    }
});

function App() {
    return (
        <>
            <GlobalStyles />
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#fff",
                        fontFamily: '"Montserrat", sans-serif'
                    }
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <Router>
                        <Routes>
                            <Route
                                path={"/"}
                                element={
                                    <CompleteLayout>
                                        <HomePage />
                                    </CompleteLayout>
                                }
                            />
                            <Route path="/register-now" element={<RegisterPage />} />
                        </Routes>
                    </Router>
                </QueryClientProvider>
            </ConfigProvider>
        </>
    );
}

export default App;
