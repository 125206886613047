import { createGlobalStyle } from "styled-components";
import { ScreenSize, screenWidths } from "./hooks";

export const GlobalStyles = createGlobalStyle`

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  
}

#root {
  background: #fff;
}
body {
  background: #fff;
  color: #fff;
}

h1,h2,h3,h4,h5,p{
  padding:0;
  margin:0;
}

h1{
  font-size:42px;
  font-weight:700;

}

h2{
  font-size:1.46rem;
  font-weight:700;
  @media (min-width: ${screenWidths[ScreenSize.MD]}) {
     font-size:2rem;
    }
}

h3{
  font-size:24px;
}

h4{
  font-size:18px;
}

h5{
  font-size:16px;
}

p{
  font-size:16px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}

a,
a:hover{
  color: #fff;
  text-decoration:none;
}


ul{
  list-style:none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 50000s ease-in-out 0s;
}


&::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

&::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  width: 3px;
  height: 3px;
}

&::-webkit-scrollbar-thumb {
  background-color: #575757;
  /* outline: 1px solid #575757; */
  /* opacity: 0.2; */
}

`;
