export const AgendaItems = [
    {
        id: 1,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-21")
    },

    {
        id: 2,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-21")
    },
    {
        id: 3,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-21"),
        endTime: new Date("2023-10-21")
    },
    {
        id: 4,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-22")
    },
    {
        id: 5,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-22")
    },
    {
        id: 6,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-22")
    },

    {
        id: 7,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-23")
    },
    {
        id: 8,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-23")
    },
    {
        id: 9,
        speaker: {
            id: 1,
            name: "Jane doe",
            title: "Founder of company",
            photo: "",
            country: "egypt",
            brief: "from 75B! 75B is the Rotterdam based graphic design agency of Rens Muis and Steffen Knöll",
            onlinePresence: {
                facebook: "https://www.google.com",
                linkedin: "https://www.google.com",
                twitter: "https://www.google.com",
                instagram: "https://www.google.com"
            }
        },
        description:
            "During the Antwerp Poster Festival 2019 Steffen Knöll will give a workshop for a limited amount of participants using only paper, scissors, box cutters and perhaps some glue. After a short introduction into the works of Steffen Knöll and their philosophy the participants will be asked to start creating their own posters using only the materials given to them.",

        startTime: new Date("2023-10-23")
    }
];
