import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const CompanyCardContainer = styled.div`
    // background-color: #f9fafb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        // height: 250px;
    }

    @media (min-width: ${screenWidths[ScreenSize.SM]}) {
        height: 200px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        height: 250px;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        height: 250px;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        height: 250px;
    }

    @media (min-width: ${screenWidths[ScreenSize.XXL]}) {
        height: 250px;
    }

    @media (min-width: ${screenWidths[ScreenSize.XXXL]}) {
        height: 250px;
    }
`;

export const CompanyCardContainerImage = styled.img`
    max-height: 100%;
    max-width: 100%;
    min-width: ${({ width }) => width ?? "150px"};
`;
