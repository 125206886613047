import { Col, Row } from "antd";
import { HTMLAttributes } from "react";
import { Carousel } from "react-bootstrap";
import { TestimonialItem } from "../../components/testimonial-item";
import { TestimonialsSectionContainer } from "./styles/testimonials-section-container";
const MobilePattern = require("../../assets/images/CTA.png");
const PlayStoreLogo = require("../../assets/images/google-button.png");
const AppleStoreLogo = require("../../assets/images/app-store-button.png");

const testimonials = [
    {
        name: "Hithem Ahmed",
        title: "CTO at Cegedim egypte",
        text: "Very insightful session, interesting discussions from pragmatic gurus."
    },
    {
        name: "Mohamed Hashish",
        title: "Managing director at Pixelogic",
        text: "It was amazing what you guys have done today! One of the best Job fairs in the past years."
    }
];

export const TestimonialsSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    return (
        <TestimonialsSectionContainer id="testimonials">
            <Row>
                <Col xs={24}>
                    <div style={{ width: "100vw" }} className=" d-flex justify-content-center">
                        <Carousel
                            defaultActiveIndex={0}
                            interval={3000}
                            className="w-100"
                            indicators={false}
                            nextIcon={null}
                            prevIcon={null}
                        >
                            {testimonials.map((t) => (
                                <Carousel.Item key={t.name}>
                                    <TestimonialItem name={t.name} title={t.title} text={t.text} />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </Col>
            </Row>
        </TestimonialsSectionContainer>
    );
};
