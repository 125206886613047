import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const ExhibitorsSectionContainer = styled.section`
    color: #000;
    width: 80%;
    margin-left: 10%;
    position: relative;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        width: 94%;
        margin-left: 3%;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        width: 90%;
        margin-left: 5%;
    }
`;
