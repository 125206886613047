import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const StatsSectionContainer = styled.section`
    background-color: #000;
    width: 100%;
    margin: 0;
    position: relative;
    padding: 180px 0px 60px 20px;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        font-size: 2rem;
        width: 100%;
        margin-bottom: 100px;
        padding: 150px 0px 60px 0px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        font-size: 2rem;
        width: 90%;
        margin-left: 5%;
        margin-bottom: 100px;
        padding: 60px 0px 60px 20px;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        font-size: 2rem;
        width: 80%;
        margin-left: 10%;
        margin-bottom: 100px;
        padding: 60px 0px 60px 50px;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        font-size: 2rem;
        width: 90%;
        margin-left: 5%;
        margin-bottom: 100px;
        padding: 60px 0px 60px 50px;
    }
`;

export const StatsSectionText = styled.p`
    width: 75%;
    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        width: 100%;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        width: 82%;
    }
`;

export const StatsSectionHeading = styled.h2`
    width: 75%;
    color: #fff;
    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        width: 100%;
    }
`;
