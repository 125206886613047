import { Col, CollapseProps, Divider, Row } from "antd";
import dayjs from "dayjs";
import { HTMLAttributes } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { AgendaCard } from "../../components";
import { TAAccordion } from "../../components/ta-accordion";
import { Colors } from "../../constants";
import { AgendaItems } from "../../data/agenda";
import { AgendaSectionContainer } from "./styles/agenda-section-container";

const agendaItems = AgendaItems;
const agendaItemsMap: any = agendaItems.reduce((agg: any, item) => {
    agg[item.startTime.toISOString()] = agg[item.startTime.toISOString()]
        ? [...agg[item.startTime.toISOString()], item]
        : [item];
    return agg;
}, []);

const text = (
    <>
        <p>
            A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
            guest in many households across the world.
        </p>{" "}
        <Divider />
        <p>
            A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
            guest in many households across the world.
        </p>{" "}
        <Divider />
        <p>
            A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome
            guest in many households across the world.
        </p>
    </>
);
const items: CollapseProps["items"] = Object.keys(agendaItemsMap).map((date) => {
    return {
        key: date,
        label: (
            <h3 style={{ fontSize: "1.25rem", fontWeight: 700, marginBottom: "20px" }}>
                {dayjs(date).format("MMM D, ddd	")}
            </h3>
        ),
        children: agendaItemsMap[date].map((item: any) => <AgendaCard key={item.id} {...item} />)
    };
});

const renderExpandIcon = (isActive?: boolean) => {
    return isActive ? (
        <AiOutlineMinus style={{ color: Colors.TA_GREEN }} size={27} />
    ) : (
        <AiOutlinePlus style={{ color: "#fff" }} size={27} />
    );
};

export const AgendaSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    return (
        <AgendaSectionContainer id="agenda">
            <Row>
                <h2 className="mb-3">Agenda</h2>
                <Col xs={24}>
                    <Row
                        gutter={24}
                        className="d-flex justify-content-center align-items-center"
                        style={{ backgroundColor: "#000" }}
                    >
                        <Col xs={24}>
                            <TAAccordion
                                items={items}
                                expandIconPosition="end"
                                expandIcon={({ isActive }) => renderExpandIcon(isActive)}
                                accordion
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </AgendaSectionContainer>
    );
};
