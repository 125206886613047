import { HTMLAttributes } from "react";
import { CompanyCardContainer, CompanyCardContainerImage } from "./styles/companycard";

export const CompanyCard: React.FC<HTMLAttributes<HTMLDivElement> & { imgSource: string; minWidth?: string }> = ({
    imgSource,
    minWidth
}) => {
    return (
        <CompanyCardContainer>
            <CompanyCardContainerImage src={imgSource} width={minWidth} alt="Entity" loading="lazy" />
        </CompanyCardContainer>
    );
};
