import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const TestimonialItemName = styled.h3`
    color: #000;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 6px;
`;

export const TestimonialItemTitle = styled.h4`
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
`;

export const TestimonialItemText = styled.p`
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
    width: 60%;
    height: 140px;
    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        width: 95%;
    }

    @media (min-width: ${screenWidths[ScreenSize.SM]}) {
        width: 90%;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        width: 80%;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        width: 60%;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        width: 60%;
    }
`;
