import React, { HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { ScreenSize, screenWidths } from '../../../hooks/media.hook';

const BackdropDiv = styled.div<{ backgroundColor?: string; media: ScreenSize }>`
	position: fixed;
	top: 0;
	left: 0;
	background-color: ${({ backgroundColor }) => backgroundColor || 'rgba(0, 0, 0, 0.5)'};
	width: 100vw;
	height: 100vh;
	z-index: 300;
	@media (min-width: ${({ media }) => screenWidths[media]}) {
		display: none;
	}
`;

export const BackDrop: React.FC<
	HTMLAttributes<HTMLDivElement> & { clicked: () => any; media: ScreenSize; show: boolean; backgroundColor?: string }
> = ({ clicked, media, show, backgroundColor }) => {
	return show
		? ReactDOM.createPortal(
				<BackdropDiv media={media} onClick={clicked} backgroundColor={backgroundColor}></BackdropDiv>,
				document.getElementById('root') as Element
		  )
		: null;
};
