import { Col, Row } from "antd";
import { HTMLAttributes } from "react";
import { ScreenSize, useMedia } from "../../hooks";
import { StatsSectionContainer, StatsSectionHeading, StatsSectionText } from "./styles/stats-section-container";
import CountUp from "react-countup";
const ColorPattern = require("../../assets/images/color-pattern-2.png");

export const StatsSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    const mdOrLarger = useMedia(ScreenSize.MD);
    return (
        <StatsSectionContainer id="stats">
            <Row gutter={24} className="d-flex align-items-center px-3">
                <Col xs={24} className="mb-5 ">
                    <StatsSectionHeading className="mb-3">JobStack: A Tech Revolution Unleashed</StatsSectionHeading>
                    <StatsSectionText>
                        Witness the transformation at JobStack 2024, where industry leaders converge to drive tech
                        innovation and growth.
                    </StatsSectionText>
                </Col>
                <Col xs={24}>
                    <Row className="d-flex flex-column flex-md-row align-items-center">
                        <Col md={6} className="d-flex flex-column align-items-center">
                            <h1 style={{ color: "#fff" }}>
                                <CountUp enableScrollSpy end={35000} duration={4} />+
                            </h1>
                            <p>Developers</p>
                        </Col>
                        <Col md={3} className="d-flex flex-column align-items-center">
                            {mdOrLarger ? (
                                <p style={{ fontSize: "80px", fontWeight: 300, display: "inline-block" }}>|</p>
                            ) : (
                                <hr
                                    style={{
                                        color: "#fff",
                                        backgroundColor: "#fff",
                                        margin: "30px 0",
                                        height: "2px",
                                        width: "20vw",
                                        border: 0,
                                        opacity: "100%"
                                    }}
                                />
                            )}
                        </Col>
                        <Col md={6} className="d-flex flex-column align-items-center">
                            <h1 style={{ color: "#fff" }}>
                                {" "}
                                <CountUp enableScrollSpy end={90} duration={4} />+
                            </h1>
                            <p>Speakers</p>
                        </Col>
                        <Col md={3} className="d-flex flex-column align-items-center">
                            {mdOrLarger ? (
                                <p style={{ fontSize: "80px", fontWeight: 300, display: "inline-block" }}>|</p>
                            ) : (
                                <hr
                                    style={{
                                        color: "#fff",
                                        backgroundColor: "#fff",
                                        margin: "30px 0",
                                        height: "2px",
                                        width: "20vw",
                                        border: 0,
                                        opacity: "100%"
                                    }}
                                />
                            )}
                        </Col>
                        <Col md={6} className="d-flex flex-column align-items-center">
                            <h1 style={{ color: "#fff" }}>
                                <CountUp enableScrollSpy end={250} duration={4} />+
                            </h1>
                            <p>Exhibitors & Partners</p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div style={{ position: "absolute", right: 0, top: 0 }}>
                <img src={ColorPattern} alt="Color pattern" loading="lazy" />
            </div>
        </StatsSectionContainer>
    );
};
