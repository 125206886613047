import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const SpeakerCardContainer = styled.div`
    border: 1px solid rgba(130, 152, 171, 0.35);
    // padding: 20px 10px 0px 10px;
`;

export const SpeakerCardImage = styled.img`
    width: 100%;
    height: 136px;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        height: 180px;
        object-fit: cover;
    }

    @media (min-width: ${screenWidths[ScreenSize.SM]}) {
        height: 270px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        height: 360px;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        height: 300px;
        object-fit: cover;
    }

    @media (min-width: 1070px) {
        height: 300px;
        object-fit: fill;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        height: 280px;
        object-fit: cover;
    }

    @media (min-width: ${screenWidths[ScreenSize.XXL]}) {
        height: 360px;
        object-fit: cover;
    }

    @media (min-width: ${screenWidths[ScreenSize.XXXL]}) {
        height: 416px;
        object-fit: fill;
    }
`;

export const ContactContainer = styled.div`
    padding: 10px 5px 0 5px;

    h4 {
        color: #000;
        font-weight: 600;
    }

    span {
        color: #8298ab;
        font-weight: 500;
        line-height: 1.25rem;
    }
    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        height: 160px;
        padding: 10px 5px 0 5px;

        h4 {
            font-weight: 700;
            font-size: 1rem;
        }
        span {
            font-size: 0.8rem;
        }
    }

    @media (min-width: ${screenWidths[ScreenSize.SM]}) {
        height: 120px;
        padding: 20px 20px 0 20px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        height: 100px;
        padding: 20px 20px 0 20px;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        height: 110px;
        padding: 20px 20px 0 20px;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        height: 120px;
        padding: 20px 20px 0 20px;
    }
`;
