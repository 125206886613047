import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";
import { Form } from "antd";

export const CompanyJoinSectionContainer = styled.section`
    position: relative;
    background-color: #000;
    min-height: 700px;
    position: relative;
    width: 100%;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        padding: 20px 10px 250px 10px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        padding: 20px 40px 250px 40px;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        padding: 50px;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        padding: 50px 100px;
    }

    @media (min-width: ${screenWidths[ScreenSize.XXL]}) {
        padding: 50px 180px;
    }
`;

export const CompanyJoinForm = styled(Form)`
    background-color: #fff;
    padding: 20px;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        width: 100%;
    }

    position: relative @media (min-width: ${screenWidths[ScreenSize.SM]}) {
        width: 80%;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        width: 70%;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        width: 100%;
    }

    input {
        height: 40px;
    }
    .ant-select-selector {
        height: 40px !important;
        display: flex;
        align-items: center;
    }
`;
