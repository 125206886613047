import { AxiosRequestConfig } from "axios";
import { taApiClient } from "./http-client";

export default class ResourceManager {
    async getResource<T>(url: string, options: AxiosRequestConfig | undefined = undefined): Promise<T | null> {
        let response = await taApiClient.get(url, options);
        if (response.status === 500) return null;
        return response.data;
    }

    async postResource<T, L>(url: string, data: T, options?: AxiosRequestConfig<T> | undefined): Promise<L | null> {
        let response = await taApiClient.post(url, data, options);
        if (response.status === 500) return null;
        return response.data;
    }

    async putResource<T, L>(url: string, data: T): Promise<L | null> {
        let response = await taApiClient.put(url, data);
        if (response.status === 500) return null;
        return response.data;
    }

    async patchResource<T, L>(url: string, data: T): Promise<L | null> {
        let response = await taApiClient.patch(url, data);
        if (response.status === 500) return null;
        return response.data;
    }

    async deleteResource<L>(url: string): Promise<L | null> {
        let response = await taApiClient.delete(url);
        if (response.status === 500) return null;
        return response.data;
    }
}
