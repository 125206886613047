const config: { [k: string]: { [k: string]: any } } = {
    development: {
        app_name: "jobstack",
        default_theme: "light",
        base_url: "http://localhost:8000",
        api_url: "http://localhost:8000",
        defaultExpandScreenSize: "lg",
        defaultPageSize: 5,
        reCaptchaSiteKey: "6LfxHzkoAAAAAFb90Nv0wX7XTCHwFqNuU4aerIg5"
    },
    testing: {
        app_name: "talentsarena",
        default_theme: "light",
        base_url: "https://3.136.86.75/api",
        api_url: "https://3.136.86.75/api",
        defaultExpandScreenSize: "lg",
        defaultPageSize: 5,
        reCaptchaSiteKey: "6LfxHzkoAAAAAFb90Nv0wX7XTCHwFqNuU4aerIg5"
    },
    staging: {
        app_name: "talentsarena",
        default_theme: "light",
        base_url: "https://3.136.86.75/api",
        api_url: "https://3.136.86.75/api",
        defaultExpandScreenSize: "lg",
        defaultPageSize: 5,
        reCaptchaSiteKey: "6LfxHzkoAAAAAFb90Nv0wX7XTCHwFqNuU4aerIg5"
    },
    production: {
        app_name: "talentsarena",
        default_theme: "light",
        base_url: "https://api.talentsarena.net",
        api_url: "https://api.talentsarena.net",
        defaultExpandScreenSize: "lg",
        defaultPageSize: 5,
        reCaptchaSiteKey: "6LfxHzkoAAAAAFb90Nv0wX7XTCHwFqNuU4aerIg5"
    }
};

export default config;
