import { HTMLAttributes } from "react";
import styled from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks/media.hook";
import { Colors } from "../../../constants";

const Toggle = styled.div<{ media: ScreenSize }>`
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: end;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
    @media (min-width: ${(props) => screenWidths[props.media]}) {
        display: none;
    }
    div {
        width: 90%;
        height: 2px;
        background-color: ${Colors.TA_GREY};
        margin: 4px 0;
    }
`;

const SideDrawerBurger: React.FC<HTMLAttributes<HTMLDivElement> & { media: ScreenSize }> = ({ onClick, media }) => (
    <Toggle onClick={onClick} media={media}>
        <div></div>
        <div></div>
        <div></div>
    </Toggle>
);

export default SideDrawerBurger;
