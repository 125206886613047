import React, { HTMLAttributes, useEffect } from "react";
import styled from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks/media.hook";
import { BackDrop } from "../Backdrop/Backdrop";

const SideDrawerContainer = styled.aside<{ media: ScreenSize }>`
    position: fixed;
    padding-top: 71px;
    width: 300px;
    flex-shrink: 0;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 400;
    background-color: #000;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;

    &.open {
        left: 0;
    }

    &.closed {
        left: -100%;
    }

    &.not-collapsed {
    }

    .sidedrawer-children {
        z-index: 2000;
    }

    @media (min-width: ${({ media }) => screenWidths[media]}) {
        width: 80px;
        z-index: 200;
        &.closed {
            left: 0;
        }
        &.not-collapsed {
            width: 220px;
        }
    }
`;

interface ISideDrawerProps {
    visible: boolean;
    onClose: () => any;
    onCollapse: (open: boolean) => any;
    collapsable: boolean;
    collapsed: boolean;
    media: ScreenSize;
}

const SideDrawer: React.FC<HTMLAttributes<HTMLDivElement> & ISideDrawerProps> = ({
    visible,
    onClose,
    collapsable,
    onCollapse,
    collapsed,
    children,
    media
}) => {
    return (
        <SideDrawerContainer
            className={[visible ? "open" : "closed", collapsed ? null : "not-collapsed"].join(" ")}
            media={media}
            onMouseEnter={() => onCollapse(false)}
            onMouseLeave={() => onCollapse(true)}
        >
            <BackDrop show={visible} clicked={onClose} media={media} />
            <div className="sidedrawer-children">{children}</div>
        </SideDrawerContainer>
    );
};

export default SideDrawer;
