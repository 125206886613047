import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const FooterContainer = styled.section`
    color: #000;
    width: 100%;
    position: relative;
    padding: 60px 0px 60px 50px;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        padding: 60px 0px 10px 10px;
    }
`;
