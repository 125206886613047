import { useState, useEffect } from "react";

export enum ScreenSize {
    XS = "xs",
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl",
    XXL = "xxl",
    XXXL = "xxxl"
}

export const screenWidths = {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1440px",
    xxxl: "1600px"
};
export const useMedia = (screenSize: ScreenSize) => {
    let [view, setView] = useState(false);

    let mql = window.matchMedia(`(min-width:${screenWidths[screenSize]})`);

    const resizeHandler = () => {
        setView(mql.matches);
    };

    useEffect(() => {
        resizeHandler();
        if (mql.addEventListener) {
            mql.addEventListener("change", resizeHandler);
        } else {
            mql.addListener(resizeHandler);
        }
        return () => {
            mql.removeEventListener("change", resizeHandler);
            mql.removeListener(resizeHandler);
        };
    }, []);
    return view;
};
