import { Carousel } from "react-bootstrap";
import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";
import { Row } from "antd";

export const AboutSectionCarousel = styled(Carousel)`
    width: 100%;

    @media (min-width: ${screenWidths[ScreenSize.SM]}) {
        width: 50%;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        width: 70%;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        width: 50%;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        width: 60%;
    }

    img {
        object-fit: cover;
        width: 100%;

        @media (min-width: ${screenWidths[ScreenSize.XS]}) {
            height: 300px;
        }

        @media (min-width: ${screenWidths[ScreenSize.SM]}) {
            height: 500px;
        }

        @media (min-width: ${screenWidths[ScreenSize.MD]}) {
            height: 400px;
        }

        @media (min-width: ${screenWidths[ScreenSize.XL]}) {
            height: 500px;
        }
    }
`;

export const PartnerContainer = styled(Row)`
    padding: 10px 100px;
    min-height: 600px;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        padding: 10px 10px;
        min-height: 300px;
    }

    @media (min-width: ${screenWidths[ScreenSize.SM]}) {
        padding: 0 40px;
        min-height: 450px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        padding: 0 80px;
        min-height: 400px;
    }

    @media (min-width: ${screenWidths[ScreenSize.XL]}) {
        padding: 0 80px;
        min-height: 500px;
    }
`;
