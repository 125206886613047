import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const TestimonialsSectionContainer = styled.section`
    background-color: #fff;
    width: 100%;
    margin: 0;
    position: relative;
    padding: 80px 0px 60px 10px;

    @media (min-width: ${screenWidths[ScreenSize.SM]}) {
        font-size: 2rem;
        width: 90%;
        margin-left: 5%;
        padding: 60px 0px 60px 30px;
        min-height: 200px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        font-size: 2rem;
        width: 90%;
        margin-left: 5%;
        padding: 60px 0px 60px 30px;
        min-height: 200px;
    }

    @media (min-width: ${screenWidths[ScreenSize.LG]}) {
        font-size: 2rem;
        width: 80%;
        margin-left: 10%;
        padding: 60px 0px 60px 50px;
        min-height: 200px;
    }
`;
