import { Menu, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { SidebarIcon } from "../widgets";
const { SubMenu } = Menu;

const disabledStyles = {
    color: "#999",
    cursor: "not-allowed"
};

export interface ISidebarItem {
    name: string;
    icon?: any;
    key: string;
    submenu?: ISidebarItem[];
    prefix?: boolean;
    props?: any;
    disabled?: boolean;
}

export const renderSidebar = (menuItems: ISidebarItem[], keyPrefix: string | null = null) => {
    return menuItems.map((item, indx) => {
        if (!item.submenu) {
            return (
                <Menu.Item
                    key={`${keyPrefix ? `${keyPrefix}/${item.key || indx}` : item.key || indx}`}
                    {...(item.props || {})}
                    style={{ color: "#fff" }}
                >
                    <Link
                        to={item.disabled ? "" : `/${item.key}`}
                        onClick={
                            item.disabled
                                ? (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                  }
                                : item.props?.onClick
                                ? (e) => {
                                      e.preventDefault();
                                      item.props.onClick(e);
                                  }
                                : undefined
                        }
                        style={item.disabled ? { ...disabledStyles } : {}}
                    >
                        <div className="d-flex align-items-center">
                            {item.icon ? <SidebarIcon disabled={item.disabled}>{item.icon}</SidebarIcon> : null}
                            <span style={{ display: "inline-block", marginLeft: "25px" }}>{item.name}</span>
                        </div>
                        {/* need to be in span because antd sidebar relys on it */}
                    </Link>
                </Menu.Item>
            );
        } else {
            return (
                <SubMenu
                    key={item.key}
                    title={
                        <div className="d-flex align-items-center">
                            <SidebarIcon> {item.icon}</SidebarIcon>
                            <span>{item.name}</span>
                        </div>
                    }
                >
                    {renderSidebar(item.submenu, keyPrefix ? `${keyPrefix}/${item.key}` : item.key)}
                </SubMenu>
            );
        }
    });
};
