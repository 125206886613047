import { HTMLAttributes } from "react";
import { ContactContainer, SpeakerCardContainer, SpeakerCardImage } from "./styles/speaker";

interface SpeakerCardProps {
    name: string;
    title: string;
    photo: string;
}

export const SpeakerCard: React.FC<HTMLAttributes<HTMLDivElement> & SpeakerCardProps> = ({ name, photo, title }) => {
    return (
        <SpeakerCardContainer>
            <SpeakerCardImage src={photo} />
            <ContactContainer>
                <h4 className="mb-2">{name}</h4>
                <span>{title}</span>
            </ContactContainer>
        </SpeakerCardContainer>
    );
};
