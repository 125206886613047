import { Collapse } from "antd";
import { styled } from "styled-components";
import { Colors } from "../../../constants";

export const TAAccordion = styled(Collapse)`
    border: none;
    drop-shadow: none;
    border-radius: none;
    background-color: transparent;

    .ant-collapse-content {
        background-color: transparent;
        border: 0;
        &-box {
            padding: 20px 0 !important;
        }
    }

    .ant-collapse-content-active {
        background-color: transparent;
        .ant-divider {
            background-color: ${Colors.TA_GREEN};
        }
    }

    .ant-collapse-item {
        background-color: transparent;
        padding: 15px 20px;
        background-color: transparent;
        border-bottom: 0px solid #fff;
        border-radius: 0 !important;

        .ant-collapse-header {
            border-bottom: 1px solid #fff;
            border-radius: 0 !important;
            color: #fff;
        }
    }

    .ant-collapse-item-active {
        border-bottom: 0px solid ${Colors.TA_GREEN};
        color: ${Colors.TA_GREEN};

        .ant-collapse-header {
            border-bottom: 1px solid ${Colors.TA_GREEN};
            color: ${Colors.TA_GREEN};
            border-radius: 0 !important;
        }
    }
`;
