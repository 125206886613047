import React, { useState, useEffect, useRef } from "react";

export const useClickedOutside = (
    initialIsVisible: boolean,
    onChange?: (clickedOutside: boolean) => void
): {
    ref: any;
    isComponentVisible: boolean;
    setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
} => {
    const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);
    const ref = useRef<HTMLElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current?.contains(event.target as Node)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        if (onChange) onChange(isComponentVisible);
    }, [isComponentVisible, onChange]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
};
