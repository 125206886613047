import { Avatar, Col, Row } from "antd";
import { HTMLAttributes } from "react";
import { GoPerson } from "react-icons/go";
import { TestimonialItemName, TestimonialItemText, TestimonialItemTitle } from "./styles/testimonial-item";

export const TestimonialItem: React.FC<
    HTMLAttributes<HTMLDivElement> & { name: string; title: string; text: string }
> = ({ name, text, title }) => {
    return (
        <Row className="d-flex align-items-center" gutter={24}>
            <Col xs={24} sm={8} md={10}>
                <div className="d-flex align-items-center mb-5 mb-sm-0">
                    <Avatar
                        style={{ backgroundColor: "#E1E9F0" }}
                        shape="circle"
                        className="me-4"
                        size={50}
                        icon={<GoPerson color="#000" size={30} />}
                    />
                    <div className="d-flex flex-column">
                        <TestimonialItemName>{name}</TestimonialItemName>
                        <TestimonialItemTitle>{title}</TestimonialItemTitle>
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={16} md={14}>
                <TestimonialItemText>"{text}"</TestimonialItemText>
            </Col>
        </Row>
    );
};
