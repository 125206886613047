import { IExhibitor, ISpeaker, PaginationParams, TAPaginatedResponse } from "../types";
import configProxy from "../utils/config";
import ResourceManager from "./resource-manager";

class JobstackService extends ResourceManager {
    exhibitorRequest = async (exhibitorRequest: any, recaptchaToken?: string | null) => {
        if (!recaptchaToken) throw new Error("ReCaptcha not verified!");
        const resp = await this.postResource<any, any>(configProxy.URIs.exhibitor_request_uri, exhibitorRequest, {
            params: {
                recaptchaToken
            }
        });
        return resp;
    };

    async getExhibitors(
        pagination: PaginationParams = { page: 1, pageSize: 10 }
    ): Promise<TAPaginatedResponse<IExhibitor[]> | null> {
        return await this.getResource(configProxy.URIs.exhibitors_uri, {
            params: { ...pagination }
        });
    }

    async getSpeakers(
        pagination: PaginationParams = { page: 1, pageSize: 10 }
    ): Promise<TAPaginatedResponse<ISpeaker[]> | null> {
        return await this.getResource(configProxy.URIs.speakers_uri, {
            params: { ...pagination }
        });
    }

    createTicket = async (ticket: any, recaptchaToken?: string | null): Promise<any | null> => {
        const resp = await this.postResource<any, any>(configProxy.URIs.tickets_uri, ticket, {
            params: {
                recaptchaToken
            }
        });
        return resp;
    };
}

export default new JobstackService();
