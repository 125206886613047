import React, { useState } from "react";
import { AiFillFolderOpen } from "react-icons/ai";
import { FaHandsHelping, FaUserTie } from "react-icons/fa";
import { GiPresent } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import { JobStackLogo } from "../components";
import { useMedia } from "../hooks/media.hook";
import configProxy from "../utils/config";
import { TANavbar, TASideBar } from "../container";

export const CompleteLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const closeHandler = () => {
        setSidebarVisible(false);
    };
    let media = configProxy.defaultExpandScreenSize;
    let desktopView = useMedia(media);
    const resource = useLocation().pathname;
    const menuItems = [
        {
            name: "Get Tickets",
            key: "get-tickets"
        },
        {
            name: "Agenda",
            key: "agenda"
        },
        {
            name: "Exhibitors",
            key: "exhibitors"
        },
        {
            name: "Speakers",
            key: "speakers"
        }
    ];

    return (
        <>
            <TASideBar
                menuItems={menuItems}
                selected={resource}
                visible={sidebarVisible}
                close={closeHandler}
                collapseOnRouteChange
                header={() => (
                    <Link to="/">
                        <div className="w-100 d-flex justify-content-center">
                            <JobStackLogo />
                        </div>
                    </Link>
                )}
            />
            <TANavbar onBurgerClick={() => setSidebarVisible(true)} />
            <div style={{ overflowX: "hidden" }}>{children}</div>
        </>
    );
};
