import { useMutation } from "@tanstack/react-query";
import { Col, Form, Input, Row, Select, message } from "antd";
import { HTMLAttributes, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ButtonType, TAButton } from "../../components";
import { countriesWithStates } from "../../data/country-states";
import exhibitorsService from "../../services/jobstack.service";
import configProxy from "../../utils/config";
import { CompanyJoinForm, CompanyJoinSectionContainer } from "./styles/company-join";

const ColorPattern = require("../../assets/images/color-pattern-3.png");

export const CompanyJoinSection: React.FC<HTMLAttributes<HTMLDivElement>> = () => {
    const [form] = Form.useForm();
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const exhibitorRequestMutation = useMutation({
        mutationFn: (exhibitorRequest) => exhibitorsService.exhibitorRequest(exhibitorRequest, recaptchaToken),
        onSuccess: (data) => {
            message.success("Your request is submitted successfully");
            form.resetFields();
            setRecaptchaToken(null);
            recaptchaRef.current?.reset();
        },
        onError: (e: Error) => {
            message.error(e.message);
        }
    });

    return (
        <CompanyJoinSectionContainer id="exhibitor-request">
            <Row gutter={24} className="d-flex justify-content-between">
                <Col xs={24} lg={10} xl={12} className="mb-5 mb-lg-0">
                    <h2 className="mb-3">Join as a company at JobStack_2024</h2>
                    <p className="mb-3">Join the biggest virtual tech job fair in the region.</p>
                    <p>
                        Jobstack 2024, is a place where best of the best technical minds come together to share their
                        experiences, find the best employers, and connect together.
                    </p>
                </Col>
                <Col xs={24} lg={12} xl={10}>
                    <CompanyJoinForm
                        layout="vertical"
                        form={form}
                        onFinish={(data: any) => {
                            if (!recaptchaToken) return;
                            exhibitorRequestMutation.mutate(data);
                        }}
                    >
                        <Row gutter={12} className="d-flex justify-content-end">
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="companyName"
                                    label="Company name"
                                    rules={[{ required: true, message: "Company name is required", whitespace: true }]}
                                >
                                    <Input placeholder="Company name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="companyWebsite"
                                    label="Company website"
                                    rules={[
                                        { required: true, message: "Company website is required" },
                                        { type: "url", message: "Invalid url" }
                                    ]}
                                >
                                    <Input placeholder="www.example.com" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} className="d-flex justify-content-end">
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="contactPersonName"
                                    label="Contact Person"
                                    rules={[
                                        {
                                            required: true,
                                            message: "contact person full name is required",
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Input placeholder="Full Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="contactPersonTitle"
                                    label="Job Title"
                                    rules={[
                                        {
                                            required: true,
                                            message: "title of contact person is required",
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Input placeholder="CEO" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} className="d-flex justify-content-end">
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Email"
                                    name="contactPersonEmail"
                                    rules={[
                                        { required: true, message: "Email of contact person is required" },
                                        { type: "email", message: "invalid email format" }
                                    ]}
                                >
                                    <Input placeholder="email" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="contactPersonPhone"
                                    label="Phone number"
                                    rules={[
                                        {
                                            required: true,
                                            message: "phone number of contact person is required",
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Input placeholder="+1 201-555-012" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} className="d-flex justify-content-end">
                            <Col xs={24}>
                                <Form.Item
                                    label="Country region"
                                    required
                                    name="country"
                                    rules={[{ required: true, message: "Country is required" }]}
                                >
                                    <Select placeholder="Select country" showSearch>
                                        {Object.keys(countriesWithStates).map((c) => (
                                            <Select.Option value={c} key={c}>
                                                {c}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Row className="w-100">
                                <Col xs={24}>
                                    <Form.Item label="message" name="message">
                                        <Input.TextArea
                                            placeholder="Please provide a short description of your business and any questions you have about JobStack."
                                            maxLength={1000}
                                            rows={5}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Row>
                        <div className="d-flex justify-content-center">
                            <ReCAPTCHA
                                theme="light"
                                sitekey={configProxy.reCaptchaSiteKey}
                                ref={recaptchaRef}
                                onChange={setRecaptchaToken}
                            />
                        </div>
                        <TAButton
                            disabled={!recaptchaToken}
                            btnType={ButtonType.PRIMARY}
                            htmlType="submit"
                            colorTheme="#000"
                            className="mt-4"
                            loading={exhibitorRequestMutation.isLoading}
                        >
                            <span style={{ color: "#fff" }}>Submit</span>
                        </TAButton>
                    </CompanyJoinForm>
                </Col>
                <div style={{ position: "absolute", left: "10%", bottom: 0 }}>
                    <img loading="lazy" alt="pattern" src={ColorPattern} />
                </div>
            </Row>
        </CompanyJoinSectionContainer>
    );
};
