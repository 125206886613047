import { ImageProps } from "rc-image";
import { NavbarContainer, NavbarNavigationItem, NavbarNavigationList, NavbarLogo } from "./styles/navbar";

interface ITANavbarComposition {
    Logo: React.FC<ImageProps>;
    NavigationList: React.FC<any>;
    NavigationItem: React.FC<any>;
}

export const Navbar: React.FC<any> & ITANavbarComposition = ({ children, ...restProps }) => (
    <NavbarContainer {...restProps}>{children}</NavbarContainer>
);

const Logo: React.FC<ImageProps> = ({ src, ...restProps }) => <NavbarLogo src={src} {...restProps} />;

const NavigationList: React.FC<any> = ({ children, ...restProps }) => (
    <NavbarNavigationList {...restProps}>{children}</NavbarNavigationList>
);

const NavigationItem: React.FC<any> = ({ children, ...restProps }) => (
    <NavbarNavigationItem {...restProps}>{children}</NavbarNavigationItem>
);

Navbar.Logo = Logo;
Navbar.NavigationList = NavigationList;
Navbar.NavigationItem = NavigationItem;
