export const countriesWithStates = {
    Albania: [
        "Berat",
        "Bulqize",
        "Delvine",
        "Devoll",
        "Dibre",
        "Durres",
        "Elbasan",
        "Fier",
        "Gjirokaster",
        "Gramsh",
        "Korce",
        "Kruje",
        "Kucove",
        "Kukes",
        "Kurbin",
        "Lezhe",
        "Librazhd",
        "Lushnje",
        "Mallakaster",
        "Malsi e Madhe",
        "Mat",
        "Mirdite",
        "Peqin",
        "Permet",
        "Pogradec",
        "Puke",
        "Sarande",
        "Shkoder",
        "Has",
        "Kavaje",
        "Kolonje",
        "Skrapar",
        "Tepelene",
        "Tirane",
        "Tropoje",
        "Vlore"
    ],
    "American Samoa": ["Manu''a", "Swains Island", "Western", "Eastern"],
    Algeria: [
        "Algiers",
        "Annabah",
        "Bashshar",
        "Batnah",
        "Bijayah",
        "Biskrah",
        "Blidah",
        "Buirah",
        "Bumardas",
        "Burj Bu Arririj",
        "Ghalizan",
        "Ghardayah",
        "Ilizi",
        "Jijili",
        "Jilfah",
        "Khanshalah",
        "Masilah",
        "Midyah",
        "Ayn Daflah",
        "Ayn Tamushanat",
        "Adrar",
        "Milah",
        "Muaskar",
        "Mustaghanam",
        "Naama",
        "Oran",
        "Sakikdah",
        "Satif",
        "Sayda",
        "Sidi ban-al-''Abbas",
        "Suq Ahras",
        "Tamanghasat",
        "Tibazah",
        "Tibissah",
        "Tilimsan",
        "Tinduf",
        "Tisamsilt",
        "Tiyarat",
        "Tizi Wazu",
        "Umm-al-Bawaghi",
        "Wahran",
        "Warqla",
        "Wilaya d Alger",
        "Wilaya de Bejaia",
        "Ouargla",
        "Qalmah",
        "Qustantinah",
        "Wilaya de Constantine",
        "al-Aghwat",
        "al-Bayadh",
        "al-Jaza''ir",
        "al-Wad",
        "ash-Shalif",
        "at-Tarif"
    ],
    Angola: [
        "Bengo",
        "Benguela",
        "Bie",
        "Cabinda",
        "Cunene",
        "Huambo",
        "Huila",
        "Kuando-Kubango",
        "Kwanza Norte",
        "Kwanza Sul",
        "Luanda",
        "Lunda Norte",
        "Lunda Sul",
        "Uige",
        "Zaire",
        "Malanje",
        "Moxico",
        "Namibe"
    ],
    Andorra: ["Andorra la Vella", "Canillo", "Encamp", "La Massana", "Les Escaldes", "Ordino", "Sant Julia de Loria"],
    Anguilla: ["Other Provinces"],
    Antarctica: [
        "Sector claimed by Argentina/Ch",
        "Sector claimed by Argentina/UK",
        "Sector claimed by Australia",
        "Sector claimed by France",
        "Sector claimed by New Zealand",
        "Sector claimed by Norway",
        "Unclaimed Sector"
    ],
    "Antigua And Barbuda": [
        "Barbuda",
        "Saint George",
        "Saint John",
        "Saint Mary",
        "Saint Paul",
        "Saint Peter",
        "Saint Philip"
    ],
    Argentina: [
        "Buenos Aires",
        "Catamarca",
        "Chaco",
        "Chubut",
        "Cordoba",
        "Corrientes",
        "Jujuy",
        "La Pampa",
        "La Rioja",
        "Mendoza",
        "Misiones",
        "Neuquen",
        "Rio Negro",
        "Salta",
        "San Juan",
        "San Luis",
        "Santa Cruz",
        "Santa Fe",
        "Santiago del Estero",
        "Tierra del Fuego",
        "Tucuman",
        "Distrito Federal",
        "Entre Rios",
        "Formosa"
    ],
    Afghanistan: [
        "Badakhshan",
        "Badgis",
        "Baglan",
        "Balkh",
        "Bamiyan",
        "Farah",
        "Faryab",
        "Gawr",
        "Gazni",
        "Herat",
        "Hilmand",
        "Jawzjan",
        "Kabul",
        "Kapisa",
        "Khawst",
        "Kunar",
        "Nimruz",
        "Nuristan",
        "Paktika",
        "Paktiya",
        "Parwan",
        "Qandahar",
        "Qunduz",
        "Samangan",
        "Sar-e Pul",
        "Takhar",
        "Uruzgan",
        "Wardag",
        "Zabul",
        "Lagman",
        "Lawghar",
        "Nangarhar"
    ],
    Aruba: ["Aruba"],
    Australia: [
        "Auckland",
        "Australian Capital Territory",
        "Balgowlah",
        "Balmain",
        "Bankstown",
        "Baulkham Hills",
        "Bonnet Bay",
        "Camberwell",
        "Carole Park",
        "Castle Hill",
        "Caulfield",
        "Chatswood",
        "Cheltenham",
        "Cherrybrook",
        "Clayton",
        "Collingwood",
        "Frenchs Forest",
        "Hawthorn",
        "Jannnali",
        "Knoxfield",
        "Melbourne",
        "Queensland",
        "South Australia",
        "Tasmania",
        "Templestowe",
        "Victoria",
        "Werribee south",
        "Western Australia",
        "Wheeler",
        "New South Wales",
        "Northern Territory",
        "Perth"
    ],
    Austria: [
        "Bundesland Salzburg",
        "Bundesland Steiermark",
        "Bundesland Tirol",
        "Burgenland",
        "Carinthia",
        "Karnten",
        "Liezen",
        "Lower Austria",
        "Niederosterreich",
        "Oberosterreich",
        "Salzburg",
        "Schleswig-Holstein",
        "Steiermark",
        "Styria",
        "Tirol",
        "Upper Austria",
        "Vorarlberg",
        "Wien"
    ],
    Azerbaijan: [
        "Abseron",
        "Baki Sahari",
        "Ganca",
        "Ganja",
        "Kalbacar",
        "Lankaran",
        "Mil-Qarabax",
        "Mugan-Salyan",
        "Nagorni-Qarabax",
        "Naxcivan",
        "Priaraks",
        "Qazax",
        "Saki",
        "Sirvan",
        "Xacmaz"
    ],
    "Bahamas The": [
        "Abaco",
        "Acklins Island",
        "Andros",
        "Berry Islands",
        "Biminis",
        "Cat Island",
        "Crooked Island",
        "Eleuthera",
        "Long Island",
        "Mayaguana",
        "New Providence",
        "Ragged Island",
        "Rum Cay",
        "San Salvador",
        "Exuma and Cays",
        "Grand Bahama",
        "Inagua Islands"
    ],
    Bahrain: [
        "Isa",
        "Badiyah",
        "Hidd",
        "Jidd Hafs",
        "Mahama",
        "Manama",
        "Sitrah",
        "al-Manamah",
        "al-Muharraq",
        "ar-Rifa''a"
    ],
    Armenia: [
        "Aragatsotn",
        "Ararat",
        "Armavir",
        "Gegharkunik",
        "Kotaik",
        "Lori",
        "Shirak",
        "Stepanakert",
        "Yerevan",
        "Syunik",
        "Tavush",
        "Vayots Dzor"
    ],
    Belgium: [
        "Antwerpen",
        "Berchem",
        "Brabant",
        "Brabant Wallon",
        "Brussel",
        "East Flanders",
        "Hainaut",
        "Namur",
        "Ontario",
        "Luxembourg",
        "Oost-Vlaanderen",
        "Provincie Brabant",
        "Vlaams-Brabant",
        "Wallonne",
        "West-Vlaanderen",
        "Limburg",
        "Liege"
    ],
    Belize: ["Belize", "Corozal", "Orange Walk", "Stann Creek", "Toledo", "Cayo"],
    Benin: [
        "Alibori",
        "Atacora",
        "Borgou",
        "Collines",
        "Couffo",
        "Donga",
        "Littoral",
        "Mono",
        "Atlantique",
        "Plateau",
        "Zou",
        "Oueme"
    ],
    Bermuda: ["Hamilton", "Saint George"],
    Bhutan: [
        "Chhukha",
        "Bumthang",
        "Chirang",
        "Daga",
        "Geylegphug",
        "Pemagatsel",
        "Punakha",
        "Rinpung",
        "Samchi",
        "Samdrup Jongkhar",
        "Shemgang",
        "Tashigang",
        "Timphu",
        "Mongar",
        "Ha",
        "Lhuntshi",
        "Tongsa",
        "Wangdiphodrang"
    ],
    Bolivia: ["Beni", "Chuquisaca", "Cochabamba", "Potosi", "Oruro", "Santa Cruz", "Tarija", "La Paz", "Pando"],
    Belarus: [
        "Homjel",
        "Brest",
        "Hrodna",
        "Mahiljow",
        "Mahilyowskaya Voblasts",
        "Minsk",
        "Petrik",
        "Vicebsk",
        "Minskaja Voblasts"
    ],
    Barbados: [
        "Christ Church",
        "Saint Andrew",
        "Saint George",
        "Saint James",
        "Saint John",
        "Saint Joseph",
        "Saint Lucy",
        "Saint Michael",
        "Saint Peter",
        "Saint Philip",
        "Saint Thomas"
    ],
    Bangladesh: [
        "Bagar Hat",
        "Bandarban",
        "Barguna",
        "Barisal",
        "Bhola",
        "Bogora",
        "Brahman Bariya",
        "Chuadanga",
        "Dhaka",
        "Dinajpur",
        "Faridpur",
        "Feni",
        "Gaybanda",
        "Gazipur",
        "Gopalganj",
        "Habiganj",
        "Jaipur Hat",
        "Jamalpur",
        "Jessor",
        "Jhalakati",
        "Jhanaydah",
        "Khagrachhari",
        "Khulna",
        "Kishorganj",
        "Koks Bazar",
        "Chandpur",
        "Chattagam",
        "Chittagong Division",
        "Komilla",
        "Kurigram",
        "Kushtiya",
        "Lakshmipur",
        "Lalmanir Hat",
        "Manikganj",
        "Maulvi Bazar",
        "Meherpur",
        "Munshiganj",
        "Naral",
        "Narayanganj",
        "Narsingdi",
        "Nator",
        "Naugaon",
        "Nawabganj",
        "Netrakona",
        "Nilphamari",
        "Noakhali",
        "Pabna",
        "Panchagarh",
        "Patuakhali",
        "Pirojpur",
        "Rajbari",
        "Madaripur",
        "Magura",
        "Maimansingh",
        "Rajshahi",
        "Rangamati",
        "Rangpur",
        "Satkhira",
        "Shariatpur",
        "Sunamganj",
        "Tangayal",
        "Thakurgaon",
        "Silhat",
        "Sirajganj",
        "Sherpur"
    ],
    Brazil: [
        "Acre",
        "Alagoas",
        "Ceara",
        "Bahia",
        "Distrito Federal",
        "Espirito Santo",
        "Estado de Sao Paulo",
        "Goias",
        "Maranhao",
        "Amapa",
        "Amazonas",
        "Para",
        "Paraiba",
        "Parana",
        "Minas Gerais",
        "Pernambuco",
        "Piaui",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rio de Janeiro",
        "Mato Grosso",
        "Mato Grosso do Sul",
        "Rondonia",
        "Roraima",
        "Santa Catarina",
        "Sao Paulo",
        "Sergipe",
        "Tocantins"
    ],
    "Bouvet Island": ["Bouvet Island"],
    "British Indian Ocean Territory": ["British Indian Ocean Territory"],
    Brunei: ["Brunei-Muara", "Belait", "Tutong", "Temburong"],
    Bulgaria: [
        "Blagoevgrad",
        "Burgas",
        "Dobrich",
        "Kardzhali",
        "Kjustendil",
        "Lovech",
        "Montana",
        "Oblast Sofiya-Grad",
        "Pazardzhik",
        "Pernik",
        "Pleven",
        "Plovdiv",
        "Razgrad",
        "Ruse",
        "Shumen",
        "Silistra",
        "Sliven",
        "Haskovo",
        "Smoljan",
        "Sofija grad",
        "Sofijska oblast",
        "Jambol",
        "Stara Zagora",
        "Gabrovo",
        "Targovishte",
        "Varna",
        "Veliko Tarnovo",
        "Vidin",
        "Vraca",
        "Yablaniza"
    ],
    "Burkina Faso": [
        "Bazega",
        "Bougouriba",
        "Boulgou",
        "Boulkiemde",
        "Comoe",
        "Ganzourgou",
        "Bale",
        "Bam",
        "Gnagna",
        "Kadiogo",
        "Ioba",
        "Komandjari",
        "Kompienga",
        "Kossi",
        "Kouritenga",
        "Kourweogo",
        "Kenedougou",
        "Leraba",
        "Mouhoun",
        "Nahouri",
        "Namentenga",
        "Noumbiel",
        "Oubritenga",
        "Passore",
        "Poni",
        "Oudalan",
        "Sanguie",
        "Sanmatenga",
        "Seno",
        "Sissili",
        "Soum",
        "Gourma",
        "Houet",
        "Yatenga",
        "Zondoma",
        "Tuy",
        "Zoundweogo",
        "Sourou",
        "Tapoa"
    ],
    Burundi: [
        "Bubanza",
        "Bujumbura",
        "Bururi",
        "Cankuzo",
        "Cibitoke",
        "Gitega",
        "Karuzi",
        "Kayanza",
        "Kirundo",
        "Makamba",
        "Rutana",
        "Ruyigi",
        "Muyinga",
        "Muramvya",
        "Ngozi"
    ],
    "Bosnia and Herzegovina": ["Federacija Bosna i Hercegovina", "Republika Srpska"],
    Botswana: [
        "Central Bobonong",
        "Central Serowe-Palapye",
        "Chobe",
        "Central Tutume",
        "Francistown",
        "Gaborone",
        "Ghanzi",
        "Central Mahalapye",
        "Kgalagadi South",
        "Kgatleng",
        "Kweneng",
        "Kgalagadi North",
        "Lobatse",
        "Ngamiland",
        "North East",
        "Ngwaketse",
        "Orapa",
        "Selibe Phikwe",
        "South East",
        "Sowa",
        "Jwaneng",
        "Okavango",
        "Central Boteti"
    ],
    Cameroon: [
        "Centre",
        "Est",
        "Littoral",
        "Nord",
        "Nord Extreme",
        "Nordouest",
        "Adamaoua",
        "Ouest",
        "Sud",
        "Sudouest"
    ],
    Cambodia: [
        "Banteay Mean Chey",
        "Bat Dambang",
        "Kampong Cham",
        "Kampong Chhnang",
        "Kampot",
        "Kandal",
        "Kampong Thum",
        "Kaoh Kong",
        "Kracheh",
        "Krong Kaeb",
        "Krong Pailin",
        "Krong Preah Sihanouk",
        "Kampong Spoeu",
        "Pousat",
        "Otdar Mean Chey",
        "Preah Vihear",
        "Prey Veaeng",
        "Rotanak Kiri",
        "Siem Reab",
        "Stueng Traeng",
        "Mondol Kiri",
        "Phnum Penh",
        "Svay Rieng",
        "Takaev"
    ],
    Canada: [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Nova Scotia",
        "Newfoundland and Labrador",
        "Northwest Territories",
        "Quebec",
        "Saskatchewan",
        "Yukon",
        "Nunavut",
        "Prince Edward Island",
        "Ontario"
    ],
    "Cape Verde": [
        "Boavista",
        "Fogo",
        "Brava",
        "Maio",
        "Sal",
        "Sao Vicente",
        "Sao Nicolau",
        "Santo Antao",
        "Sao Tiago"
    ],
    "Central African Republic": [
        "Bamingui-Bangoran",
        "Bangui",
        "Basse-Kotto",
        "Haut-Mbomou",
        "Haute-Kotto",
        "Mambere-Kadei",
        "Nana-Gribizi",
        "Mbomou",
        "Ombella Mpoko",
        "Ouaka",
        "Ouham",
        "Nana-Mambere",
        "Lobaye",
        "Sangha-Mbaere",
        "Vakaga",
        "Ouham-Pende",
        "Kemo"
    ],
    China: [
        "Anhui",
        "Anhui Province",
        "Aomen",
        "Beijing",
        "Anhui Sheng",
        "Fujian Sheng",
        "Gansu",
        "Guangdong",
        "Guangdong Sheng",
        "Guangxi",
        "Guizhou",
        "Hainan",
        "Hebei",
        "Heilongjiang",
        "Henan",
        "Hubei",
        "Hunan",
        "Chongqing",
        "Fujian",
        "Jiangsu",
        "Beijing Shi",
        "Jiangsu Sheng",
        "Liaoning Sheng",
        "Nei Monggol",
        "Liaoning",
        "Ningxia Hui",
        "Qinghai",
        "Shandong",
        "Shaanxi",
        "Shandong Sheng",
        "Jiangxi",
        "Jilin",
        "Shanghai",
        "Shanxi",
        "Xinjiang",
        "Sichuan",
        "Xizang",
        "Tianjin",
        "Xianggang",
        "Yunnan",
        "Zhejiang",
        "Zhejiang Sheng"
    ],
    Chile: [
        "Araucania",
        "Atacama",
        "Bio Bio",
        "Coquimbo",
        "Libertador General Bernardo O",
        "Los Lagos",
        "Aisen",
        "Antofagasta",
        "Magellanes",
        "Tarapaca",
        "Valparaiso",
        "Metropolitana de Santiago",
        "Maule",
        "Metropolitana"
    ],
    "Christmas Island": ["Christmas Island"],
    "Cocos (Keeling) Islands": ["Cocos (Keeling) Islands"],
    Comoros: ["Mwali", "Nzwani", "Njazidja"],
    Colombia: [
        "Atlantico",
        "Bogota",
        "Bolivar",
        "Boyaca",
        "Caldas",
        "Caqueta",
        "Casanare",
        "Cauca",
        "Antioquia",
        "Cesar",
        "Choco",
        "Arauca",
        "Cordoba",
        "Cundinamarca",
        "Guainia",
        "Guaviare",
        "Huila",
        "Amazonas",
        "La Guajira",
        "Magdalena",
        "Meta",
        "Quindio",
        "Risaralda",
        "Putumayo",
        "San Andres y Providencia",
        "Santander",
        "Sucre",
        "Tolima",
        "Valle del Cauca",
        "Norte de Santander",
        "Vaupes",
        "Narino",
        "Vichada"
    ],
    Chad: [
        "Batha",
        "Biltine",
        "Bourkou-Ennedi-Tibesti",
        "Chari-Baguirmi",
        "Logone Occidental",
        "Logone Oriental",
        "Mayo-Kebbi",
        "Moyen-Chari",
        "Ouaddai",
        "Salamat",
        "Kanem",
        "Lac",
        "Guera",
        "Tandjile"
    ],
    "Cook Islands": [
        "Aitutaki",
        "Atiu",
        "Mangaia",
        "Manihiki",
        "Mauke",
        "Mitiaro",
        "Nassau",
        "Pukapuka",
        "Rakahanga",
        "Rarotonga",
        "Tongareva"
    ],
    "Costa Rica": ["Alajuela", "Cartago", "Guanacaste", "Heredia", "Puntarenas", "Limon", "San Jose"],
    "Cote D''Ivoire (Ivory Coast)": [
        "Abidjan",
        "Agneby",
        "Bafing",
        "Denguele",
        "Lacs",
        "Fromager",
        "Marahoue",
        "Lagunes",
        "Moyen-Comoe",
        "Haut-Sassandra",
        "Moyen-Cavally",
        "N''zi-Comoe",
        "Dix-huit Montagnes",
        "Sud-Comoe",
        "Vallee du Bandama",
        "Worodougou",
        "Zanzan",
        "Savanes",
        "Sud-Bandama",
        "Sassandra"
    ],
    "Croatia (Hrvatska)": [
        "Bjelovar-Bilogora",
        "Dubrovnik-Neretva",
        "Grad Zagreb",
        "Istra",
        "Karlovac",
        "Koprivnica-Krizhevci",
        "Lika-Senj",
        "Krapina-Zagorje",
        "Medhimurje",
        "Medimurska Zupanija",
        "Osijek-Baranja",
        "Shibenik-Knin",
        "Sisak-Moslavina",
        "Slavonski Brod-Posavina",
        "Split-Dalmacija",
        "Varazhdin",
        "Osjecko-Baranjska Zupanija",
        "Virovitica-Podravina",
        "Pozhega-Slavonija",
        "Primorje-Gorski Kotar",
        "Zadar",
        "Zagreb",
        "Vukovar-Srijem"
    ],
    "Cayman Islands": ["Grand Cayman"],
    Cuba: [
        "Camaguey",
        "Ciego de Avila",
        "Cienfuegos",
        "Ciudad de la Habana",
        "Granma",
        "Guantanamo",
        "Habana",
        "Holguin",
        "Isla de la Juventud",
        "La Habana",
        "Matanzas",
        "Las Tunas",
        "Pinar del Rio",
        "Villa Clara",
        "Sancti Spiritus",
        "Santiago de Cuba"
    ],
    Cyprus: ["Government controlled area", "Nicosia District", "Limassol", "Paphos", "Turkish controlled area"],
    "Democratic Republic Of The Congo": [
        "Bandundu",
        "Bas-Congo",
        "Equateur",
        "Haut-Congo",
        "Kasai-Occidental",
        "Kasai-Oriental",
        "Maniema",
        "Nord-Kivu",
        "Katanga",
        "Kinshasa",
        "Sud-Kivu"
    ],
    "Czech Republic": [
        "Jihochesky",
        "Jihomoravsky",
        "Karlovarsky",
        "Klecany",
        "Kralovehradecky",
        "Central Bohemian",
        "Frycovice",
        "Liberecky",
        "Jihocesky Kraj",
        "Olomoucky Kraj",
        "Olomoucky",
        "Plzensky",
        "Pardubicky",
        "Praha",
        "Smirice",
        "Rajhrad",
        "Lipov",
        "Moravskoslezsky",
        "Unicov",
        "South Moravian",
        "Ustecky",
        "Straz nad Nisou",
        "Stredochesky",
        "Valletta",
        "Velesin",
        "Vysochina",
        "Zlinsky"
    ],
    Djibouti: ["Jibuti", "Tajurah", "Ubuk", "Ali Sabih", "Dikhil"],
    Dominica: [
        "Saint Andrew",
        "Saint David",
        "Saint George",
        "Saint John",
        "Saint Joseph",
        "Saint Luke",
        "Saint Mark",
        "Saint Patrick",
        "Saint Paul",
        "Saint Peter"
    ],
    "Republic Of The Congo": [
        "Bouenza",
        "Brazzaville",
        "Cuvette",
        "Kouilou",
        "Lekoumou",
        "Likouala",
        "Sangha",
        "Niari",
        "Pool",
        "Plateaux"
    ],
    "East Timor": [
        "Aileu",
        "Ainaro",
        "Ambeno",
        "Dili",
        "Cova Lima",
        "Lautem",
        "Ermera",
        "Liquica",
        "Manatuto",
        "Manufahi",
        "Baucau",
        "Bobonaro",
        "Viqueque"
    ],
    "Dominican Republic": [
        "Dajabon",
        "Azua",
        "Duarte",
        "El Seybo",
        "Barahona",
        "Elias Pina",
        "Distrito Nacional",
        "Hato Mayor",
        "Espaillat",
        "Bahoruco",
        "Independencia",
        "Maria Trinidad Sanchez",
        "Monsenor Nouel",
        "La Altagracia",
        "Monte Cristi",
        "Monte Plata",
        "La Vega",
        "Peravia",
        "Pedernales",
        "Puerto Plata",
        "Salcedo",
        "Samana",
        "La Romana",
        "San Juan",
        "Sanchez Ramirez",
        "Santiago",
        "Santiago Rodriguez",
        "San Pedro de Macoris",
        "Valverde",
        "San Cristobal"
    ],
    Denmark: [
        "Arhus",
        "Bornholm",
        "Fyn",
        "Frederiksborg",
        "Hovedstaden",
        "Kobenhavns Amt",
        "Kobenhavns Kommune",
        "Nordjylland",
        "Kobenhavn",
        "Ribe",
        "Ringkobing",
        "Roervig",
        "Roskilde",
        "Roslev",
        "Sjaelland",
        "Soeborg",
        "Sonderjylland",
        "Storstrom",
        "Syddanmark",
        "Toelloese",
        "Vestsjalland",
        "Vejle",
        "Viborg"
    ],
    Ecuador: [
        "Canar",
        "Carchi",
        "Chimborazo",
        "Bolivar",
        "Azuay",
        "Cotopaxi",
        "El Oro",
        "Esmeraldas",
        "Galapagos",
        "Guayas",
        "Loja",
        "Manabi",
        "Morona Santiago",
        "Orellana",
        "Napo",
        "Pastaza",
        "Pichincha",
        "Los Rios",
        "Imbabura",
        "Sucumbios",
        "Zamora Chinchipe",
        "Tungurahua"
    ],
    Egypt: [
        "Asyut",
        "Beni Suef",
        "Port Said",
        "Cairo",
        "Damietta",
        "Kafr Alsheikh",
        "Matruh",
        "Aswan",
        "Dakahlia",
        "Fayoum",
        "Gharbia",
        "Alexandria",
        "North Sinai",
        "Red Sea",
        "Beheira",
        "Ismalia",
        "Giza",
        "Monufya",
        "Sawhaj",
        "South Sinai",
        "Qalubia",
        "New Valley",
        "Luxor",
        "Suez",
        "Sharqia",
        "Minya",
        "Qina"
    ],
    "El Salvador": [
        "Cabanas",
        "Chalatenango",
        "Cuscatlan",
        "Ahuachapan",
        "La Libertad",
        "San Miguel",
        "San Salvador",
        "San Vicente",
        "Santa Ana",
        "Sonsonate",
        "Usulutan",
        "La Union",
        "La Paz",
        "Morazan"
    ],
    "Equatorial Guinea": ["Annobon", "Bioko Norte", "Litoral", "Bioko Sur", "Wele-Nzas", "Kie-Ntem", "Centro Sur"],
    Eritrea: ["Anseba", "Debub", "Gash-Barka", "Maekel", "Debub-Keih-Bahri", "Semien-Keih-Bahri"],
    "Falkland Islands": ["Falkland Islands", "South Georgia"],
    "Faroe Islands": [
        "Klaksvik",
        "Nor ara Eysturoy",
        "Streymoy",
        "Su uroy",
        "Sy ra Eysturoy",
        "Vaga",
        "Torshavn",
        "Sandoy",
        "Nor oy"
    ],
    "Fiji Islands": ["Central", "Northern", "Western", "South Pacific", "Eastern"],
    Finland: [
        "Ahvenanmaa",
        "Etela-Pohjanmaa",
        "Etela-Savo",
        "Etela-Suomen Laani",
        "Etela-Karjala",
        "Ita-Suomen Laani",
        "Keski-Pohjanmaa",
        "Ita-Uusimaa",
        "Kymenlaakso",
        "Lansi-Suomen Laani",
        "Keski-Suomi",
        "Lappi",
        "Kainuu",
        "Northern Savonia",
        "Oulun Laani",
        "Kanta-Hame",
        "Ostrobothnia",
        "Paijat-Hame",
        "Pirkanmaa",
        "Pohjois-Savo",
        "Saarijarvi",
        "Pohjois-Karjala",
        "Southern Savonia",
        "Satakunta",
        "Tavastia Proper",
        "Pohjanmaa",
        "Pohjois-Pohjanmaa",
        "Uleaborgs Lan",
        "Varsinais-Suomi",
        "Uusimaa"
    ],
    France: [
        "Aisne",
        "Albi Le Sequestre",
        "Allier",
        "Alpes-Cote dAzur",
        "Alpes-Maritimes",
        "Ain",
        "Alpes-de-Haute-Provence",
        "Alsace",
        "Aquitaine",
        "Aube",
        "Aude",
        "Auvergne",
        "Ardennes",
        "Bas-Rhin",
        "Aveyron",
        "Basse-Normandie",
        "Ardeche",
        "Ariege",
        "Brittany",
        "Burgundy",
        "Calvados",
        "Cantal",
        "Cedex",
        "Centre",
        "Bourgogne",
        "Charente-Maritime",
        "Charente",
        "Cher",
        "Correze",
        "Cote-d''Or",
        "Corse-du-Sud",
        "Cotes-d''Armor",
        "Bretagne",
        "Bouches-du-Rhone",
        "Deux-Sevres",
        "Dordogne",
        "Doubs",
        "Drome",
        "Eure",
        "Essonne",
        "Feucherolles",
        "Eure-et-Loir",
        "Crolles",
        "Finistere",
        "Gard",
        "Franche-Comte",
        "Creuse",
        "Haute-Corse",
        "Haute-Garonne",
        "Haute-Loire",
        "Haute-Marne",
        "Haute-Saone",
        "Haute-Savoie",
        "Gironde",
        "Gers",
        "Hautes-Alpes",
        "Haut-Rhin",
        "Haute-Vienne",
        "Hautes-Pyrenees",
        "Ille-et-Vilaine",
        "Ile-de-France",
        "Indre-et-Loire",
        "Indre",
        "Isere",
        "Jura",
        "Klagenfurt",
        "Hauts-de-Seine",
        "Languedoc-Roussillon",
        "Le Castellet",
        "Larcay",
        "Landes",
        "Herault",
        "Loir-et-Cher",
        "Loire",
        "Loiret",
        "Lorraine",
        "Loire-Atlantique",
        "Lot",
        "Lot-et-Garonne",
        "Lower Normandy",
        "Maine-et-Loire",
        "Manche",
        "Limousin",
        "Lozere",
        "Marne",
        "Mayenne",
        "Meuse",
        "Meurthe-et-Moselle",
        "Le Creusot",
        "Nievre",
        "Nord",
        "Moselle",
        "Oise",
        "Nord-Pas-de-Calais",
        "Orne",
        "Pas-de-Calais",
        "Paris",
        "Midi-Pyrenees",
        "Morbihan",
        "Pays de la Loire",
        "Pays-de-la-Loire",
        "Pyrenees-Orientales",
        "Quelmes",
        "Picardy",
        "Rhone",
        "Pyrenees-Atlantiques",
        "Saint Ouen",
        "Rhone-Alpes",
        "Saone-et-Loire",
        "Saint Viatre",
        "Puy-de-Dome",
        "Seine-Saint-Denis",
        "Savoie",
        "Somme",
        "Seine-et-Marne",
        "Sophia Antipolis",
        "Souvans",
        "Tarn",
        "Sarthe",
        "Treignac",
        "Upper Normandy",
        "Val-d''Oise",
        "Territoire de Belfort",
        "Val-de-Marne",
        "Var",
        "Vaucluse",
        "Vellise",
        "Vendee",
        "Seine-Maritime",
        "Vosges",
        "Yonne",
        "Yvelines",
        "Vienne",
        "Tarn-et-Garonne"
    ],
    "French Guiana": ["Saint-Laurent-du-Maroni", "Cayenne"],
    Ethiopia: [
        "Addis Abeba",
        "Afar",
        "Benishangul",
        "Amhara",
        "Diredawa",
        "Harar",
        "Gambella",
        "Mekele",
        "Jigjiga",
        "Somali",
        "Southern",
        "Oromia",
        "Tigray"
    ],
    "External Territories of Australia": ["Christmas Island", "Cocos Islands", "Coral Sea Islands"],
    "French Polynesia": ["Iles du Vent", "Iles sous le Vent", "Marquesas", "Tubuai", "Tuamotu"],
    Gabon: [
        "Estuaire",
        "Haut-Ogooue",
        "Moyen-Ogooue",
        "Ngounie",
        "Nyanga",
        "Ogooue-Lolo",
        "Ogooue-Ivindo",
        "Ogooue-Maritime",
        "Woleu-Ntem"
    ],
    "Gambia The": ["Basse", "Banjul", "Brikama", "Janjanbureh", "Kerewan", "Kanifing", "Mansakonko", "Kuntaur"],
    "French Southern Territories": ["Amsterdam", "Crozet Islands", "Kerguelen"],
    Georgia: [
        "Ajaria",
        "Abhasia",
        "Guria",
        "Imereti",
        "Kaheti",
        "Kvemo Kartli",
        "Mcheta-Mtianeti",
        "Racha",
        "Shida Kartli",
        "Tbilisi",
        "Samche-Zhavaheti",
        "Samagrelo-Zemo Svaneti"
    ],
    Germany: [
        "Auvergne",
        "Baden-Wurttemberg",
        "Bayern",
        "Bavaria",
        "Beilstein Wurtt",
        "Berlin",
        "Brandenburg",
        "Bremen",
        "Dreisbach",
        "Heroldstatt",
        "Hessen",
        "Kortenberg",
        "Laasdorf",
        "Land Baden-Wurttemberg",
        "Land Bayern",
        "Land Brandenburg",
        "Land Hessen",
        "Hamburg",
        "Hannover",
        "Land Mecklenburg-Vorpommern",
        "Land Rheinland-Pfalz",
        "Land Nordrhein-Westfalen",
        "Land Sachsen",
        "Freistaat Bayern",
        "Mecklenburg-Vorpommern",
        "Mulfingen",
        "Munich",
        "Neubeuern",
        "Niedersachsen",
        "Noord-Holland",
        "Nordrhein-Westfalen",
        "North Rhine-Westphalia",
        "Osterode",
        "Rheinland-Pfalz",
        "Rhineland-Palatinate",
        "Saarland",
        "Lower Saxony",
        "Sachsen",
        "Sachsen-Anhalt",
        "Saxony",
        "Land Thuringen",
        "Schleswig-Holstein",
        "Thuringia",
        "Land Sachsen-Anhalt",
        "Webling",
        "Weinstrabe",
        "schlobborn"
    ],
    Estonia: [
        "Harju",
        "Hiiu",
        "Ida-Viru",
        "Laane-Viru",
        "Laane",
        "Parnu",
        "Jarva",
        "Saare",
        "Tartu",
        "Valga",
        "Jogeva",
        "Viljandi",
        "Voru",
        "Polva",
        "Rapla"
    ],
    Ghana: [
        "Ashanti",
        "Brong-Ahafo",
        "Central",
        "Upper East",
        "Greater Accra",
        "Upper West",
        "Volta",
        "Northern",
        "Western",
        "Eastern"
    ],
    Gibraltar: ["Gibraltar"],
    Guadeloupe: [
        "Basse-Terre",
        "Grande-Terre",
        "Iles des Saintes",
        "Saint Martin",
        "Marie-Galante",
        "La Desirade",
        "Saint Barthelemy"
    ],
    Guam: [
        "Agat",
        "Barrigada",
        "Chalan-Pago-Ordot",
        "Agana Heights",
        "Dededo",
        "Hagatna",
        "Mongmong-Toto-Maite",
        "Santa Rita",
        "Inarajan",
        "Sinajana",
        "Talofofo",
        "Merizo",
        "Tamuning",
        "Yigo",
        "Yona",
        "Mangilao"
    ],
    Guatemala: [
        "Alta Verapaz",
        "El Progreso",
        "Escuintla",
        "Guatemala",
        "Huehuetenango",
        "Chiquimula",
        "Izabal",
        "Jalapa",
        "Jutiapa",
        "Peten",
        "Baja Verapaz",
        "Quezaltenango",
        "Chimaltenango",
        "Quiche",
        "Santa Rosa",
        "Solola",
        "San Marcos",
        "Suchitepequez",
        "Zacapa",
        "Totonicapan",
        "Retalhuleu",
        "Sacatepequez"
    ],
    "Guernsey and Alderney": [
        "Alderney",
        "Castel",
        "Saint Andrew",
        "Saint Pierre du Bois",
        "Saint Peter Port",
        "Saint Sampson",
        "Saint Saviour",
        "Sark",
        "Torteval",
        "Vale",
        "Saint Martin",
        "Forest"
    ],
    Guinea: [
        "Conakry",
        "Coyah",
        "Dabola",
        "Dalaba",
        "Dinguiraye",
        "Faranah",
        "Forecariah",
        "Beyla",
        "Boffa",
        "Fria",
        "Boke",
        "Gaoual",
        "Gueckedou",
        "Kankan",
        "Kerouane",
        "Kissidougou",
        "Koundara",
        "Kouroussa",
        "Labe",
        "Lola",
        "Macenta",
        "Mali",
        "Koubia",
        "Kindia",
        "Pita",
        "Mandiana",
        "Siguiri",
        "Mamou",
        "Nzerekore",
        "Telimele",
        "Tougue",
        "Yomou"
    ],
    Grenada: [
        "Carriacou-Petite Martinique",
        "Saint Andrew",
        "Saint Davids",
        "Saint George''s",
        "Saint Patrick",
        "Saint Mark",
        "Saint John"
    ],
    Guyana: [
        "Barima-Waini",
        "Cuyuni-Mazaruni",
        "East Berbice-Corentyne",
        "Essequibo Islands-West Demerar",
        "Mahaica-Berbice",
        "Pomeroon-Supenaam",
        "Potaro-Siparuni",
        "Upper Takutu-Upper Essequibo",
        "Demerara-Mahaica",
        "Upper Demerara-Berbice"
    ],
    "Guinea-Bissau": ["Bafata", "Gabu", "Oio", "Quinara", "Tombali", "Cacheu", "Bolama", "Bissau"],
    Greece: [
        "Acharnes",
        "Argolis",
        "Ahaia",
        "Arkadia",
        "Attiki",
        "Arta",
        "Ayion Oros",
        "Crete",
        "Attica",
        "Dodekanisos",
        "Drama",
        "Evia",
        "Evritania",
        "Evros",
        "Evvoia",
        "Aitolia kai Akarnania",
        "Florina",
        "Halandri",
        "Grevena",
        "Hania",
        "Heraklion",
        "Halkidiki",
        "Hios",
        "Ilia",
        "Fokis",
        "Fthiotis",
        "Imathia",
        "Ioannina",
        "Iraklion",
        "Karditsa",
        "Kerkira",
        "Kiklades",
        "Kilkis",
        "Kastoria",
        "Kefallinia",
        "Korinthia",
        "Kozani",
        "Lakonia",
        "Larisa",
        "Lasithi",
        "Kavala",
        "Messinia",
        "Nomos Attikis",
        "Nomos Zakynthou",
        "Pella",
        "Pieria",
        "Piraios",
        "Preveza",
        "Rethimni",
        "Levkas",
        "Magnisia",
        "Rodopi",
        "Serrai",
        "Samos",
        "Lesvos",
        "Thesprotia",
        "Thessaloniki",
        "West Greece",
        "Zakinthos",
        "Xanthi",
        "Trikala",
        "Voiotia"
    ],
    Greenland: [
        "Aasiaat",
        "Ammassalik",
        "Illoqqortoormiut",
        "Kangaatsiaq",
        "Maniitsoq",
        "Nanortalik",
        "Narsaq",
        "Nuuk",
        "Paamiut",
        "Qaanaaq",
        "Qaqortoq",
        "Ilulissat",
        "Qasigiannguit",
        "Sisimiut",
        "Qeqertarsuaq",
        "Udenfor kommunal inddeling",
        "Upernavik",
        "Uummannaq",
        "Ivittuut"
    ],
    "Hong Kong S.A.R.": ["Hong Kong"],
    Hungary: [
        "Bacs-Kiskun",
        "Budapest",
        "Csongrad",
        "Fejer",
        "Gyor-Moson-Sopron",
        "Hajdu-Bihar",
        "Heves",
        "Borsod-Abauj-Zemplen",
        "Jasz-Nagykun-Szolnok",
        "Bekes",
        "Nograd",
        "Pest",
        "Somogy",
        "Komarom-Esztergom",
        "Szabolcs-Szatmar-Bereg",
        "Baranya",
        "Tolna",
        "Vas",
        "Veszprem",
        "Zala"
    ],
    Iceland: [
        "Austurland",
        "Gullbringusysla",
        "Nor urland eystra",
        "Hofu borgarsva i",
        "Nor urland vestra",
        "Su urnes",
        "Su urland",
        "Vestfir ir",
        "Vesturland"
    ],
    India: [
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
        "Pondicherry",
        "Punjab",
        "Rajasthan"
    ],
    Indonesia: [
        "Aceh",
        "Bali",
        "Banten",
        "Bangka-Belitung",
        "Bengkulu",
        "Gorontalo",
        "Jakarta",
        "Jambi",
        "Jawa Barat",
        "Jawa Tengah",
        "Jawa Timur",
        "Kalimantan Barat",
        "Kalimantan Selatan",
        "Kalimantan Tengah",
        "Gandaria",
        "Kalimantan Timur",
        "Kendal",
        "Maluku",
        "Lampung",
        "Maluku Utara",
        "Riau",
        "Riau Kepulauan",
        "Solo",
        "Sulawesi Selatan",
        "Sulawesi Tengah",
        "Sulawesi Tenggara",
        "Sulawesi Utara",
        "Sumatera Barat",
        "Sumatera Selatan",
        "Sumatera Utara",
        "Yogyakarta",
        "Nusa Tenggara Timur",
        "Nusa Tenggara Barat",
        "Papua"
    ],
    Iran: [
        "Ardabil",
        "Azarbayjan-e Bakhtari",
        "Azarbayjan-e Khavari",
        "Bushehr",
        "Chahar Mahal-e Bakhtiari",
        "Esfahan",
        "Fars",
        "Gilan",
        "Golestan",
        "Hamadan",
        "Hormozgan",
        "Ilam",
        "Kermanshah",
        "Kerman",
        "Khorasan",
        "Khuzestan",
        "Kohgiluyeh-e Boyerahmad",
        "Kordestan",
        "Lorestan",
        "Mazandaran",
        "Qazvin",
        "Qom",
        "Semnan",
        "Tehran",
        "Sistan-e Baluchestan",
        "Yazd",
        "Zanjan",
        "Markazi",
        "Ostan-e Esfahan"
    ],
    Honduras: [
        "Atlantida",
        "Choluteca",
        "Colon",
        "Comayagua",
        "Copan",
        "Cortes",
        "Distrito Central",
        "Francisco Morazan",
        "Gracias a Dios",
        "El Paraiso",
        "Intibuca",
        "Islas de la Bahia",
        "La Paz",
        "Lempira",
        "Ocotepeque",
        "Olancho",
        "Santa Barbara",
        "Valle",
        "Yoro"
    ],
    "Heard and McDonald Islands": ["Heard and McDonald Islands"],
    Iraq: [
        "Dahuk",
        "Baghdad",
        "Dhi Qar",
        "Diyala",
        "Erbil",
        "Irbil",
        "Karbala",
        "Babil",
        "Salah-ad-Din",
        "Wasit",
        "al-Anbar",
        "al-Basrah",
        "al-Muthanna",
        "al-Qadisiyah",
        "an-Najaf",
        "Kurdistan",
        "Maysan",
        "as-Sulaymaniyah",
        "Ninawa",
        "at-Ta''mim"
    ],
    Ireland: [
        "Armagh",
        "Cavan",
        "Carlow",
        "Dublin",
        "Galway",
        "Kerry",
        "Kildare",
        "Kilkenny",
        "Laois",
        "Leinster",
        "Leitrim",
        "Limerick",
        "Loch Garman",
        "Longford",
        "Louth",
        "Mayo",
        "Meath",
        "Monaghan",
        "Offaly",
        "Roscommon",
        "Cork",
        "Sligo",
        "Clare",
        "Donegal",
        "Tipperary North Riding",
        "Tipperary South Riding",
        "Ulster",
        "Waterford",
        "Westmeath",
        "Wicklow",
        "Wexford"
    ],
    Italy: [
        "Abruzzo",
        "Abruzzi",
        "Agrigento",
        "Alessandria",
        "Ancona",
        "Arezzo",
        "Ascoli Piceno",
        "Asti",
        "Avellino",
        "Bari",
        "Belluno",
        "Basilicata",
        "Benevento",
        "Bologna",
        "Bolzano",
        "Brescia",
        "Brindisi",
        "Calabria",
        "Campania",
        "Bergamo",
        "Catania",
        "Caserta",
        "Chieti",
        "Como",
        "Cosenza",
        "Cremona",
        "Cuneo",
        "Cartoceto",
        "Biella",
        "Florence",
        "Forli-Cesena",
        "Friuli-Venezia Giulia",
        "Frosinone",
        "Genoa",
        "Gorizia",
        "Firenze",
        "Ferrara",
        "Lazio",
        "L''Aquila",
        "Lecce",
        "Lecco Province",
        "Lecco",
        "Liguria",
        "Lodi",
        "Lombardia",
        "Emilia-Romagna",
        "Marche",
        "Messina",
        "Macerata",
        "Milan",
        "Molise",
        "Modena",
        "Lombardy",
        "Mantova",
        "Molteno",
        "Novara",
        "Padova",
        "Parma",
        "Pavia",
        "Perugia",
        "Pesaro-Urbino",
        "Piacenza",
        "Piedmont",
        "Piemonte",
        "Pisa",
        "Pordenone",
        "Potenza",
        "Puglia",
        "Montenegro",
        "Monza and Brianza",
        "Naples",
        "Reggio Emilia",
        "Rimini",
        "Roma",
        "Salerno",
        "Sardegna",
        "Sassari",
        "Savona",
        "Sicilia",
        "Siena",
        "Sondrio",
        "Torino",
        "Toscana",
        "Trapani",
        "Trentino-Alto Adige",
        "Trento",
        "Treviso",
        "Udine",
        "Umbria",
        "Valle d''Aosta",
        "Varese",
        "Veneto",
        "Venezia",
        "Verbano-Cusio-Ossola",
        "Vercelli",
        "Taranto",
        "Verona",
        "Vicenza",
        "Viterbo",
        "South Tyrol",
        "Teramo"
    ],
    Japan: [
        "Akita",
        "Aomori",
        "Chiba",
        "Ehime",
        "Fukui",
        "Aichi",
        "Fukuoka",
        "Fukushima",
        "Gifu",
        "Gumma",
        "Hiroshima",
        "Hokkaido",
        "Hyogo",
        "Ibaraki",
        "Ishikawa",
        "Iwate",
        "Kagawa",
        "Kochi",
        "Kumamoto",
        "Kyoto",
        "Mie",
        "Miyagi",
        "Miyazaki",
        "Nagano",
        "Nagasaki",
        "Nara",
        "Niigata",
        "Oita",
        "Okayama",
        "Okinawa",
        "Osaka",
        "Saga",
        "Saitama",
        "Shiga",
        "Shimane",
        "Kagoshima",
        "Kanagawa",
        "Kanto",
        "Shizuoka",
        "Tochigi",
        "Tokushima",
        "Tokyo",
        "Tottori",
        "Yamaguchi",
        "Yamanashi",
        "Toyama",
        "Wakayama",
        "Yamagata"
    ],
    Jamaica: [
        "Buxoro Viloyati",
        "Clarendon",
        "Hanover",
        "Kingston",
        "Manchester",
        "Portland",
        "Saint Elizabeth",
        "Saint James",
        "Saint Mary",
        "Saint Thomas",
        "Trelawney",
        "Westmoreland",
        "Saint Ann",
        "Saint Catherine",
        "Saint Andrews"
    ],
    Haiti: ["Artibonite", "Centre", "Grand''Anse", "Nord", "Nord-Est", "Nord-Ouest", "Ouest", "Sud", "Sud-Est"],
    Jersey: [
        "Grouville",
        "Saint Brelade",
        "Saint Clement",
        "Saint Helier",
        "Saint John",
        "Saint Lawrence",
        "Saint Martin",
        "Saint Mary",
        "Saint Peter",
        "Saint Saviour",
        "Trinity"
    ],
    Kiribati: [
        "Abaiang",
        "Banaba",
        "Beru",
        "Butaritari",
        "Kiritimati",
        "Kuria",
        "Maiana",
        "Makin",
        "Marakei",
        "Nikunau",
        "Nonouti",
        "Onotoa",
        "Phoenix Islands",
        "Tabiteuea North",
        "Tabiteuea South",
        "Tabuaeran",
        "Tamana",
        "Tarawa North",
        "Abemana",
        "Aranuka",
        "Arorae",
        "Tarawa South",
        "Teraina"
    ],
    "Korea North": [
        "Chagangdo",
        "Hamgyeongbukto",
        "Hamgyeongnamdo",
        "Kangweon",
        "Nampo",
        "Hwanghaenamdo",
        "Pyeonganbukto",
        "Pyeongannamdo",
        "Pyeongyang",
        "Hwanghaebukto",
        "Yanggang",
        "Kaeseong"
    ],
    "Korea South": [
        "Busan",
        "Chollanam",
        "Chungbuk",
        "Chungcheongbuk",
        "Chungcheongnam",
        "Chungnam",
        "Daegu",
        "Gangwon-do",
        "Goyang-si",
        "Chollabuk",
        "Gyeonggi-do",
        "Gyeongsang",
        "Gyeongsangnam-do",
        "Incheon",
        "Cheju",
        "Kwangju",
        "Kyeonggi",
        "Kyeongsangbuk",
        "Kyeongsangnam",
        "Kyonggi-do",
        "Kyungbuk-Do",
        "Kyunggi-Do",
        "Kyunggi-do",
        "Pusan",
        "Seoul",
        "Sudogwon",
        "Taegu",
        "Kangweon",
        "Jeonbuk",
        "Ulsan",
        "Taejon-gwangyoksi",
        "Wonju",
        "gwangyoksi",
        "Taejeon",
        "Jeju-Si"
    ],
    Kazakhstan: [
        "Akmola",
        "Aktobe",
        "Almati",
        "Atirau",
        "Batis Kazakstan",
        "Burlinsky Region",
        "Karagandi",
        "Kostanay",
        "Mankistau",
        "Ontustik Kazakstan",
        "Pavlodar",
        "Sigis Kazakstan",
        "Soltustik Kazakstan",
        "Taraz",
        "Akmecet"
    ],
    Kuwait: [
        "Hawalli",
        "Al Asimah",
        "Mishref",
        "Qadesiya",
        "Safat",
        "Salmiya",
        "al-Ahmadi",
        "al-Farwaniyah",
        "al-Jahra",
        "al-Kuwayt"
    ],
    Kyrgyzstan: ["Batken", "Bishkek", "Chui", "Issyk-Kul", "Jalal-Abad", "Naryn", "Osh", "Talas"],
    Laos: [
        "Bokeo",
        "Attopu",
        "Bolikhamsay",
        "Champasak",
        "Khammouane",
        "Oudomxay",
        "Luang Prabang",
        "Phongsaly",
        "Saravan",
        "Savannakhet",
        "Sekong",
        "Luang Nam Tha",
        "Xaignabury",
        "Xiang Khuang",
        "Viangchan Province",
        "Viangchan Prefecture",
        "Houaphanh"
    ],
    Kenya: ["Central", "Coast", "Eastern", "Nairobi", "North Eastern", "Nyanza", "Rift Valley", "Western"],
    Jordan: [
        "Ajlun",
        "Amman",
        "Irbid",
        "Jarash",
        "Ma''an",
        "Madaba",
        "al-''Aqabah",
        "al-Balqa",
        "al-Karak",
        "al-Mafraq",
        "at-Tafilah",
        "az-Zarqa"
    ],
    Liberia: [
        "Bomi",
        "Bong",
        "Grand Bassa",
        "Grand Cape Mount",
        "Grand Gedeh",
        "Loffa",
        "Margibi",
        "Maryland and Grand Kru",
        "Montserrado",
        "Nimba",
        "Rivercess",
        "Sinoe"
    ],
    Libya: [
        "Ajdabiya",
        "Fezzan",
        "Banghazi",
        "Darnah",
        "Ghadamis",
        "Gharyan",
        "Misratah",
        "Surt",
        "Murzuq",
        "Sabha",
        "Tarhunah",
        "Tripolitania",
        "Sawfajjin",
        "Tubruq",
        "Yafran",
        "Zlitan",
        "al-''Aziziyah",
        "al-Fatih",
        "al-Jabal al Akhdar",
        "al-Jufrah",
        "al-Khums",
        "al-Kufrah",
        "an-Nuqat al-Khams",
        "ash-Shati",
        "az-Zawiyah",
        "Tarabulus"
    ],
    Liechtenstein: [
        "Balzers",
        "Eschen",
        "Gamprin",
        "Mauren",
        "Planken",
        "Ruggell",
        "Schaan",
        "Schellenberg",
        "Triesenberg",
        "Vaduz",
        "Triesen"
    ],
    Lithuania: [
        "Anyksciai",
        "Alytaus",
        "Kauno",
        "Klaipedos",
        "Shiauliu",
        "Taurages",
        "Telshiu",
        "Telsiai",
        "Panevezhio",
        "Utenos",
        "Vilniaus",
        "Marijampoles",
        "Panevezys"
    ],
    Luxembourg: [
        "Capellen",
        "Echternach",
        "Clervaux",
        "Diekirch",
        "Esch-sur-Alzette",
        "Grevenmacher",
        "Luxembourg",
        "Mersch",
        "Vianden",
        "Wiltz",
        "Remich",
        "Redange"
    ],
    "Macau S.A.R.": ["Macau"],
    Latvia: [
        "Aizkraukles",
        "Aluksnes",
        "Balvu",
        "Bauskas",
        "Cesu",
        "Daugavpils",
        "Daugavpils City",
        "Dobeles",
        "Gulbenes",
        "Jurmala City",
        "Kraslavas",
        "Kuldigas",
        "Liepaja",
        "Liepajas",
        "Limbazhu",
        "Jekabspils",
        "Jelgava",
        "Jelgavas",
        "Preilu",
        "Rezekne",
        "Rezeknes",
        "Riga",
        "Rigas",
        "Saldus",
        "Talsu",
        "Tukuma",
        "Valkas",
        "Valmieras",
        "Ventspils",
        "Ventspils City",
        "Ogres",
        "Madonas",
        "Ludzas"
    ],
    Lebanon: [
        "Beirut",
        "Jabal Lubnan",
        "Mohafazat Mont-Liban",
        "Mohafazat Liban-Nord",
        "Sidon",
        "al-Biqa",
        "al-Janub",
        "an-Nabatiyah",
        "ash-Shamal"
    ],
    Lesotho: [
        "Berea",
        "Butha-Buthe",
        "Leribe",
        "Mafeteng",
        "Maseru",
        "Mokhotlong",
        "Mohale''s Hoek",
        "Qacha''s Nek",
        "Thaba-Tseka",
        "Quthing"
    ],
    Malawi: [
        "Balaka",
        "Blantyre City",
        "Dedza",
        "Dowa",
        "Karonga",
        "Kasungu",
        "Lilongwe City",
        "Machinga",
        "Mangochi",
        "Mchinji",
        "Mulanje",
        "Mwanza",
        "Mzimba",
        "Mzuzu City",
        "Nkhata Bay",
        "Nkhotakota",
        "Nsanje",
        "Ntcheu",
        "Chiradzulu",
        "Ntchisi",
        "Chitipa",
        "Phalombe",
        "Chikwawa",
        "Rumphi",
        "Salima",
        "Thyolo",
        "Zomba Municipality"
    ],
    Malaysia: [
        "Johor",
        "Labuan",
        "Melaka",
        "Negeri Johor",
        "Negeri Sembilan",
        "Pahang",
        "Kelantan",
        "Penang",
        "Kedah",
        "Kuala Lumpur",
        "Perlis",
        "Perak",
        "Sarawak",
        "Selangor",
        "Sembilan",
        "Terengganu",
        "Sabah",
        "Pulau Pinang"
    ],
    Madagascar: ["Antananarivo", "Fianarantsoa", "Antsiranana", "Mahajanga", "Toamasina", "Toliary"],
    Maldives: [
        "Alif Alif",
        "Alif Dhaal",
        "Baa",
        "Dhaal",
        "Faaf",
        "Gaaf Alif",
        "Gaaf Dhaal",
        "Ghaviyani",
        "Kaaf",
        "Laam",
        "Lhaviyani",
        "Haa Dhaal",
        "Nuun",
        "Male",
        "Raa",
        "Miim",
        "Shaviyani",
        "Haa Alif",
        "Thaa",
        "Vaav",
        "Siin"
    ],
    Mali: ["Bamako", "Gao", "Kayes", "Kidal", "Koulikoro", "Mopti", "Tombouctou", "Segou", "Sikasso"],
    Malta: ["Gozo and Comino", "Northern", "Inner Harbour", "Outer Harbour", "Valletta", "South Eastern", "Western"],
    "Marshall Islands": [
        "Ailinlaplap",
        "Ailuk",
        "Aur",
        "Arno",
        "Bikini",
        "Enewetak",
        "Ebon",
        "Jabat",
        "Kwajalein",
        "Lae",
        "Lib",
        "Likiep",
        "Majuro",
        "Maloelap",
        "Mejit",
        "Mili",
        "Jaluit",
        "Namorik",
        "Namu",
        "Kili",
        "Rongelap",
        "Ujae",
        "Utrik",
        "Wotho",
        "Wotje"
    ],
    Martinique: ["Fort-de-France", "Le Marin", "Saint-Pierre", "La Trinite"],
    "Man (Isle of)": ["Castletown", "Laxey", "Douglas", "Port Erin", "Onchan", "Peel", "Port Saint Mary", "Ramsey"],
    Mauritania: [
        "Adrar",
        "Assaba",
        "Brakna",
        "Dhakhlat Nawadibu",
        "Nawakshut",
        "Qidimagha",
        "Inshiri",
        "Qurqul",
        "Taqant",
        "Tiris Zammur",
        "Trarza",
        "Hudh-al-Gharbi",
        "Hudh-ash-Sharqi"
    ],
    Mauritius: [
        "Black River",
        "Eau Coulee",
        "Flacq",
        "Grand Port",
        "Floreal",
        "Moka",
        "Plaines Wilhelm",
        "Pamplempousses",
        "Port Louis",
        "Rose Hill",
        "Savanne",
        "Riviere du Rempart",
        "Rodrigues"
    ],
    Macedonia: [
        "Berovo",
        "Bitola",
        "Brod",
        "Debar",
        "Delchevo",
        "Demir Hisar",
        "Gevgelija",
        "Kichevo",
        "Kochani",
        "Kratovo",
        "Kriva Palanka",
        "Kumanovo",
        "Krushevo",
        "Negotino",
        "Ohrid",
        "Prilep",
        "Probishtip",
        "Radovish",
        "Gostivar",
        "Resen",
        "Shtip",
        "Skopje",
        "Struga",
        "Strumica",
        "Sveti Nikole",
        "Kavadarci",
        "Tetovo",
        "Valandovo",
        "Veles",
        "Vinica"
    ],
    Mayotte: ["Mayotte", "Pamanzi"],
    Mexico: [
        "Baja California",
        "Aguascalientes",
        "Baja California Sur",
        "Coahuila",
        "Colima",
        "Distrito Federal",
        "Durango",
        "Estado de Mexico",
        "Guanajuato",
        "Chihuahua",
        "Guerrero",
        "Jalisco",
        "Hidalgo",
        "Campeche",
        "Chiapas",
        "Morelos",
        "Nuevo Leon",
        "Nayarit",
        "Oaxaca",
        "Puebla",
        "Queretaro",
        "Quintana Roo",
        "Mexico",
        "Michoacan",
        "Sonora",
        "Tabasco",
        "Tamaulipas",
        "Tlaxcala",
        "Veracruz",
        "Yucatan",
        "Zacatecas",
        "San Luis Potosi",
        "Sinaloa"
    ],
    Micronesia: ["Kusaie", "Pohnpei", "Yap", "Chuuk"],
    Moldova: [
        "Balti",
        "Cahul",
        "Chisinau",
        "Edinet",
        "Lapusna",
        "Orhei",
        "Soroca",
        "Taraclia",
        "Tighina",
        "Transnistria",
        "Gagauzia",
        "Chisinau Oras",
        "Ungheni"
    ],
    Monaco: ["Monaco-Ville", "Monte Carlo", "La Condamine", "Fontvieille"],
    Mozambique: [
        "Gaza",
        "Cabo Delgado",
        "Inhambane",
        "Manica",
        "Maputo",
        "Maputo Provincia",
        "Tete",
        "Zambezia",
        "Nampula",
        "Sofala",
        "Niassa"
    ],
    Myanmar: [
        "Ayeyarwady",
        "Bago",
        "Chin",
        "Kachin",
        "Kayah",
        "Kayin",
        "Magway",
        "Mandalay",
        "Rakhine",
        "Mon",
        "Sagaing",
        "Shan",
        "Tanintharyi",
        "Nay Pyi Taw",
        "Yangon"
    ],
    Namibia: [
        "Caprivi",
        "Erongo",
        "Hardap",
        "Karas",
        "Kavango",
        "Khomas",
        "Kunene",
        "Omaheke",
        "Ohangwena",
        "Omusati",
        "Oshana",
        "Otjozondjupa",
        "Oshikoto"
    ],
    Nauru: ["Yaren"],
    Montserrat: ["Montserrat"],
    Morocco: [
        "Agadir",
        "Casablanca",
        "Doukkala-Abda",
        "Gharb-Chrarda-Beni Hssen",
        "Guelmim",
        "Marrakech-Tensift-Al Haouz",
        "Meknes-Tafilalet",
        "Kenitra",
        "Chaouia-Ouardigha",
        "Oriental",
        "Province de Tanger",
        "Sala Al Jadida",
        "Oujda",
        "Rabat-Sale-Zammour-Zaer",
        "Fes-Boulemane",
        "Tangier-Tetouan",
        "Souss Massa-Draa",
        "Settat",
        "Taza-Al Hoceima-Taounate",
        "Wilaya de Casablanca",
        "Wilaya de Rabat-Sale",
        "Tadla-Azilal"
    ],
    Nepal: [
        "Bagmati",
        "Bheri",
        "Dhawalagiri",
        "Gandaki",
        "Janakpur",
        "Lumbini",
        "Mahakali",
        "Mechi",
        "Narayani",
        "Rapti",
        "Sagarmatha",
        "Karnali",
        "Koshi",
        "Seti"
    ],
    "Netherlands Antilles": ["Curacao", "Bonaire", "Saba", "Sint Eustatius", "Sint Maarten"],
    Mongolia: [
        "Arhangaj",
        "Bajan-Olgij",
        "Bajanhongor",
        "Bulgan",
        "Dornod",
        "Darhan-Uul",
        "Dornogovi",
        "Dundgovi",
        "Govi-Altaj",
        "Govisumber",
        "Hentij",
        "Hovd",
        "Hovsgol",
        "Omnogovi",
        "Ovorhangaj",
        "Orhon",
        "Suhbaatar",
        "Tov",
        "Ulaanbaatar",
        "Uvs",
        "Zavhan",
        "Selenge"
    ],
    Nicaragua: [
        "Atlantico Norte",
        "Atlantico Sur",
        "Boaco",
        "Chinandega",
        "Chontales",
        "Carazo",
        "Esteli",
        "Granada",
        "Jinotega",
        "Leon",
        "Madriz",
        "Masaya",
        "Managua",
        "Matagalpa",
        "Nueva Segovia",
        "Rio San Juan",
        "Rivas"
    ],
    Niger: ["Diffa", "Dosso", "Maradi", "Agadez", "Niamey", "Tahoua", "Tillabery", "Zinder"],
    Nigeria: [
        "Abia",
        "Abuja Federal Capital Territor",
        "Adamawa",
        "Bayelsa",
        "Akwa Ibom",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "Gombe",
        "Bauchi",
        "Imo",
        "Anambra",
        "Jigawa",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Katsina",
        "Kaduna",
        "Niger",
        "Nassarawa",
        "Ogun",
        "Ondo",
        "Osun",
        "Kano",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara",
        "Plateau",
        "Rivers",
        "Oyo"
    ],
    Niue: ["Niue"],
    "New Caledonia": ["Nord", "Iles", "Sud"],
    "Norfolk Island": ["Norfolk Island"],
    "Netherlands The": [
        "Amsterdam",
        "Benelux",
        "Gelderland",
        "Groningen",
        "Flevoland",
        "Limburg",
        "Noord-Brabant",
        "Noord-Holland",
        "Overijssel",
        "Drenthe",
        "Friesland",
        "South Holland",
        "Utrecht",
        "Zeeland",
        "Zuid-Holland"
    ],
    "Northern Mariana Islands": ["Rota", "Saipan", "Northern Islands", "Tinian"],
    "New Zealand": [
        "Area Outside Region",
        "Bay of Plenty",
        "Auckland",
        "Canterbury",
        "Christchurch",
        "Marlborough",
        "Nelson",
        "Gisborne",
        "Northland",
        "Manawatu-Wanganui",
        "Otago",
        "Rodney",
        "Southland",
        "Taranaki",
        "Hawke''s Bay",
        "Tasman",
        "Waikato",
        "West Coast",
        "Wellington"
    ],
    Pakistan: [
        "Baluchistan",
        "Federal Capital Area",
        "Federally administered Tribal",
        "North-West Frontier",
        "Northern Areas",
        "Punjab",
        "Sind"
    ],
    Oman: [
        "Al Buraimi",
        "Dhufar",
        "Musandam",
        "Masqat",
        "Wadi Kabir",
        "Rusayl",
        "ad-Dakhiliyah",
        "ash-Sharqiyah",
        "adh-Dhahirah",
        "al-Batinah"
    ],
    "Palestinian Territory Occupied": [
        "Bayt Lahm",
        "Bethlehem",
        "Dayr-al-Balah",
        "Ghazzah",
        "Janin",
        "Ghazzah ash-Shamaliyah",
        "Ariha",
        "Khan Yunis",
        "Nabulus",
        "Qalqilyah",
        "Tubas",
        "Tulkarm",
        "al-Khalil",
        "al-Quds",
        "Salfit",
        "Ram Allah wal-Birah",
        "Rafah"
    ],
    Palau: [
        "Aimeliik",
        "Airai",
        "Angaur",
        "Hatobohei",
        "Kayangel",
        "Melekeok",
        "Koror",
        "Ngaraard",
        "Ngardmau",
        "Ngatpang",
        "Ngaremlengui",
        "Ngchesar",
        "Ngerchelong",
        "Peleliu",
        "Ngiwal",
        "Sonsorol"
    ],
    Panama: [
        "Bocas del Toro",
        "Chiriqui",
        "Cocle",
        "Colon",
        "Darien",
        "Embera",
        "Herrera",
        "Kuna Yala",
        "Los Santos",
        "Ngobe Bugle",
        "Panama",
        "Veraguas"
    ],
    "Papua new Guinea": [
        "East New Britain",
        "East Sepik",
        "Eastern Highlands",
        "Enga",
        "Gulf",
        "Fly River",
        "Madang",
        "Manus",
        "Milne Bay",
        "New Ireland",
        "North Solomons",
        "Oro",
        "Sandaun",
        "Simbu",
        "Southern Highlands",
        "West New Britain",
        "National Capital District",
        "Western Highlands",
        "Morobe"
    ],
    Paraguay: [
        "Alto Paraguay",
        "Alto Parana",
        "Amambay",
        "Asuncion",
        "Canendiyu",
        "Central",
        "Concepcion",
        "Cordillera",
        "Guaira",
        "Itapua",
        "Misiones",
        "Neembucu",
        "Paraguari",
        "Presidente Hayes",
        "San Pedro",
        "Caaguazu",
        "Caazapa",
        "Boqueron"
    ],
    Peru: [
        "Amazonas",
        "Ancash",
        "Apurimac",
        "Arequipa",
        "Ayacucho",
        "Cajamarca",
        "Cusco",
        "Junin",
        "La Libertad",
        "Lambayeque",
        "Lima y Callao",
        "Loreto",
        "Madre de Dios",
        "Huancavelica",
        "Huanuco",
        "Ica",
        "Moquegua",
        "Pasco",
        "Tacna",
        "San Martin",
        "Tumbes",
        "Piura",
        "Puno",
        "Ucayali"
    ],
    Norway: [
        "Akershus",
        "Aust Agder",
        "Bergen",
        "Buskerud",
        "Finnmark",
        "Nord Trondelag",
        "Nordland",
        "Hordaland",
        "Oestfold",
        "Oppland",
        "Oslo",
        "Hedmark",
        "Moere og Romsdal",
        "Rogaland",
        "Sykkylven",
        "Telemark",
        "Troms",
        "Vest Agder",
        "Vestfold",
        "ÃƒÂ˜stfold",
        "Sogn og Fjordane",
        "Stavern",
        "Soer Troendelag"
    ],
    Portugal: [
        "Abrantes",
        "Acores",
        "Braga",
        "Algarve",
        "Centro",
        "Distrito de Leiria",
        "Distrito de Viana do Castelo",
        "Distrito do Porto",
        "Distrito de Vila Real",
        "Alentejo",
        "Norte",
        "Paivas",
        "Madeira",
        "Lisboa e Vale do Tejo"
    ],
    "Puerto Rico": [
        "Arecibo",
        "Bayamon",
        "Carolina",
        "Florida",
        "Guayama",
        "Mayaguez-Aguadilla",
        "Ponce",
        "Salinas",
        "San Juan",
        "Humacao"
    ],
    "Pitcairn Island": ["Pitcairn Island"],
    Philippines: [
        "Batangas",
        "Bicol",
        "Bulacan",
        "Cagayan",
        "Caraga",
        "Central Luzon",
        "Central Mindanao",
        "Central Visayas",
        "Cordillera",
        "Eastern Visayas",
        "Davao",
        "Greater Metropolitan Area",
        "Laguna",
        "Ilocos",
        "Luzon",
        "Metropolitan Manila Area",
        "Mactan",
        "Muslim Mindanao",
        "Southern Tagalog",
        "Western Mindanao",
        "Southern Mindanao",
        "Western Visayas",
        "Northern Mindanao"
    ],
    Reunion: ["Saint-Benoit", "Saint-Denis", "Saint-Pierre", "Saint-Paul"],
    Qatar: [
        "Doha",
        "Jarian-al-Batnah",
        "Umm Salal",
        "al-Jumayliyah",
        "al-Khawr",
        "ar-Rayyan",
        "al-Wakrah",
        "ash-Shamal",
        "ad-Dawhah",
        "al-Ghuwayriyah"
    ],
    Russia: [
        "Adygeja",
        "Aga",
        "Amur",
        "Arhangelsk",
        "Bashkortostan",
        "Astrahan",
        "Belgorod",
        "Burjatija",
        "Brjansk",
        "Chechenija",
        "Alanija",
        "Altaj",
        "Cheljabinsk",
        "Chita",
        "Chukotka",
        "Chuvashija",
        "Habarovsk",
        "Hakasija",
        "Hanty-Mansija",
        "Ingusetija",
        "Irkutsk",
        "Ivanovo",
        "Dagestan",
        "Jamalo-Nenets",
        "Jevrej",
        "Jaroslavl",
        "Kabardino-Balkarija",
        "Kaliningrad",
        "Kalmykija",
        "Kamchatka",
        "Kaluga",
        "Karachaj-Cherkessija",
        "Karelija",
        "Kemerovo",
        "Kirov",
        "Khabarovskiy Kray",
        "Komi",
        "Korjakija",
        "Komi-Permjakija",
        "Evenkija",
        "Gorno-Altaj",
        "Kostroma",
        "Krasnodar",
        "Krasnojarsk",
        "Krasnoyarskiy Kray",
        "Kurgan",
        "Lipeck",
        "Magadan",
        "Marij El",
        "Mordovija",
        "Moscow",
        "Moskovskaja Oblast",
        "Moskovskaya Oblast",
        "Kursk",
        "Leningrad",
        "Moskva",
        "Murmansk",
        "Nenets",
        "Nizhnij Novgorod",
        "Novgorod",
        "Novokusnezk",
        "Orjol",
        "Penza",
        "Perm",
        "Omsk",
        "Primorje",
        "Orenburg",
        "Pskov",
        "Pskovskaya Oblast",
        "Novosibirsk",
        "Sahalin",
        "Samara",
        "Samarskaya",
        "Sankt-Peterburg",
        "Saratov",
        "Smolensk",
        "Stavropol",
        "Sverdlovsk",
        "Tajmyrija",
        "Rostov",
        "Saha",
        "Tambov",
        "Tatarstan",
        "Tjumen",
        "Rjazan",
        "Tomsk",
        "Tula",
        "Tver",
        "Ulyanovskaya Oblast",
        "Ust-Orda",
        "Vladimir",
        "Volgograd",
        "Vologda",
        "Tyva",
        "Uljanovsk",
        "Voronezh",
        "Udmurtija"
    ],
    Romania: [
        "Alba",
        "Arad",
        "Arges",
        "Bacau",
        "Bihor",
        "Botosani",
        "Bistrita-Nasaud",
        "Braila",
        "Brasov",
        "Bucuresti",
        "Buzau",
        "Caras-Severin",
        "Calarasi",
        "Cluj",
        "Constanta",
        "Dambovita",
        "Covasna",
        "Dolj",
        "Galati",
        "Giurgiu",
        "Gorj",
        "Harghita",
        "Hunedoara",
        "Iasi",
        "Ialomita",
        "Ilfov",
        "Maramures",
        "Mures",
        "Neamt",
        "Mehedinti",
        "Prahova",
        "Olt",
        "Salaj",
        "Satu Mare",
        "Sibiu",
        "Teleorman",
        "Timis",
        "Tulcea",
        "Valcea",
        "Vaslui",
        "Vrancea",
        "Sondelor",
        "Suceava"
    ],
    Poland: [
        "Biale Blota",
        "Dobroszyce",
        "Dolnoslaskie",
        "Koscian",
        "Krakow",
        "Kujawsko-Pomorskie",
        "Lodzkie",
        "Lubelskie",
        "Lubuskie",
        "Malomice",
        "Malopolskie",
        "Mazowieckie",
        "Mirkow",
        "Opolskie",
        "Ostrowiec",
        "Podkarpackie",
        "Podlaskie",
        "Dziekanow Lesny",
        "Polska",
        "Pomorskie",
        "Kartuzy",
        "Poznan",
        "Pruszkow",
        "Rymanowska",
        "Rzeszow",
        "Slaskie",
        "Stare Pole",
        "Swietokrzyskie",
        "Wejherowo",
        "Wielkopolskie",
        "Wroclaw",
        "Zachodnio-Pomorskie",
        "Warsaw",
        "Zukowo",
        "Warminsko-Mazurskie",
        "Hopowo"
    ],
    Rwanda: [
        "Butare",
        "Byumba",
        "Cyangugu",
        "Gikongoro",
        "Gisenyi",
        "Kibuye",
        "Kigali-ngali",
        "Ruhengeri",
        "Kibungo",
        "Gitarama"
    ],
    "Saint Helena": ["Ascension", "Gough Island", "Tristan da Cunha", "Saint Helena"],
    "Saint Kitts And Nevis": [
        "Saint Anne Sandy Point",
        "Saint George Basseterre",
        "Saint George Gingerland",
        "Christ Church Nichola Town",
        "Saint James Windward",
        "Saint John Capesterre",
        "Saint John Figtree",
        "Saint Mary Cayon",
        "Saint Paul Capesterre",
        "Saint Paul Charlestown",
        "Trinity Palmetto Point",
        "Saint Thomas Lowland",
        "Saint Peter Basseterre",
        "Saint Thomas Middle Island"
    ],
    "Saint Lucia": [
        "Anse-la-Raye",
        "Canaries",
        "Castries",
        "Choiseul",
        "Dennery",
        "Laborie",
        "Soufriere",
        "Vieux Fort",
        "Gros Inlet",
        "Micoud"
    ],
    Samoa: [
        "Aiga-i-le-Tai",
        "A''ana",
        "Atua",
        "Fa''asaleleaga",
        "Gaga''emauga",
        "Gagaifomauga",
        "Palauli",
        "Vaisigano",
        "Satupa''itea",
        "Va''a-o-Fonoti",
        "Tuamasaga"
    ],
    "Saint Pierre and Miquelon": ["Saint-Pierre", "Miquelon-Langlade"],
    "San Marino": [
        "Acquaviva",
        "Chiesanuova",
        "Borgo Maggiore",
        "Domagnano",
        "Faetano",
        "Fiorentino",
        "Montegiardino",
        "San Marino",
        "Serravalle"
    ],
    "Saint Vincent And The Grenadines": [
        "Charlotte",
        "Grenadines",
        "Saint Andrew",
        "Saint George",
        "Saint Patrick",
        "Saint David"
    ],
    "Sao Tome and Principe": ["Agua Grande", "Cantagalo", "Lemba", "Me-Zochi", "Pague", "Lobata"],
    "Saudi Arabia": [
        "Al Khobar",
        "Aseer",
        "Asir",
        "Ash Sharqiyah",
        "Central Province",
        "Eastern Province",
        "Jizan",
        "Makkah",
        "Jawf",
        "Najran",
        "Qasim",
        "Tabuk",
        "Western Province",
        "al-Bahah",
        "ar-Riyad",
        "Ha''il",
        "al-Hudud-ash-Shamaliyah",
        "al-Madinah"
    ],
    Senegal: [
        "Dakar",
        "Diourbel",
        "Fatick",
        "Kaolack",
        "Kolda",
        "Thies",
        "Ziguinchor",
        "Saint-Louis",
        "Louga",
        "Tambacounda"
    ],
    Serbia: ["Central Serbia", "Kosovo and Metohija", "Vojvodina"],
    Slovakia: [
        "Bratislavsky",
        "Banskobystricky",
        "Nitriansky",
        "Trenciansky",
        "Trnavsky",
        "Zilinsky",
        "Presovsky",
        "Kosicky"
    ],
    Slovenia: [
        "Benedikt",
        "Gorenjska",
        "Gorishka",
        "Notranjsko-krashka",
        "Koroshka",
        "Obalno-krashka",
        "Obcina Domzale",
        "Obcina Vitanje",
        "Osrednjeslovenska",
        "Podravska",
        "Slovenian Littoral",
        "Spodnjeposavska",
        "Zasavska",
        "Jugovzhodna Slovenija",
        "Savinjska",
        "Pomurska"
    ],
    "Smaller Territories of the UK": ["Pitcairn"],
    "Solomon Islands": [
        "Central",
        "Choiseul",
        "Isabel",
        "Makira and Ulawa",
        "Guadalcanal",
        "Western",
        "Rennell and Bellona",
        "Temotu",
        "Malaita"
    ],
    Somalia: [
        "Awdal",
        "Bakol",
        "Banadir",
        "Bari",
        "Bay",
        "Jubbada Hose",
        "Jubbadha Dexe",
        "Hiran",
        "Mudug",
        "Galgudug",
        "Gedo",
        "Nugal",
        "Sanag",
        "Shabellaha Dhexe",
        "Togdher",
        "Woqoyi Galbed",
        "Shabellaha Hose"
    ],
    "Sierra Leone": ["Eastern", "Northern", "Southern", "Western"],
    "South Africa": [
        "Eastern Cape",
        "Free State",
        "Gauteng",
        "Kempton Park",
        "Kramerville",
        "KwaZulu Natal",
        "Limpopo",
        "Mpumalanga",
        "North West",
        "Northern Cape",
        "Parow",
        "Table View",
        "Umtentweni",
        "Western Cape"
    ],
    "South Georgia": ["South Georgia"],
    Singapore: ["Singapore"],
    Spain: [
        "A Coruna",
        "Alacant",
        "Andalucia",
        "Asturias",
        "Avila",
        "Badajoz",
        "Albacete",
        "Balears",
        "Barcelona",
        "Alava",
        "Almeria",
        "Caceres",
        "Cadiz",
        "Cantabria",
        "Castello",
        "Catalunya",
        "Ceuta",
        "Biscay",
        "Ciudad Real",
        "Comunidad Autonoma de Canarias",
        "Comunidad Autonoma de Cataluna",
        "Comunidad Autonoma de Galicia",
        "Comunidad Autonoma de las Isla",
        "Comunidad Autonoma del Princip",
        "Burgos",
        "Comunidad Valenciana",
        "Bertamirans",
        "Gipuzkoa",
        "Cuenca",
        "Girona",
        "Granada",
        "Guadalajara",
        "Guipuzcoa",
        "Cordoba",
        "Huelva",
        "Huesca",
        "Las Palmas",
        "Lerida",
        "Leon",
        "Lleida",
        "Madrid",
        "Lugo",
        "Jaen",
        "La Rioja",
        "Malaga",
        "Melilla",
        "Ourense",
        "Pais Vasco",
        "Palencia",
        "Pontevedra",
        "Salamanca",
        "Santa Cruz de Tenerife",
        "Segovia",
        "Sevilla",
        "Tarragona",
        "Soria",
        "Tenerife",
        "Toledo",
        "Teruel",
        "Valencia",
        "Vizcaya",
        "Valladolid",
        "Zamora",
        "Murcia",
        "Navarra",
        "Zaragoza"
    ],
    Sudan: [
        "A''ali-an-Nil",
        "Bahr-al-Jabal",
        "Central Equatoria",
        "Gharb Bahr-al-Ghazal",
        "Janub Darfur",
        "Janub Kurdufan",
        "Junqali",
        "Kassala",
        "Gharb Kurdufan",
        "Gharb Darfur",
        "Shamal Bahr-al-Ghazal",
        "Nahr-an-Nil",
        "Gharb-al-Istiwa''iyah",
        "Shamal Darfur",
        "Shamal Kurdufan",
        "Warab",
        "al-Bahr-al-Ahmar",
        "Wilayat al Khartum",
        "Sharq-al-Istiwa''iyah",
        "al-Buhayrat",
        "al-Jazirah",
        "al-Khartum",
        "al-Qadarif",
        "al-Wahdah",
        "an-Nil-al-Abyad",
        "an-Nil-al-Azraq",
        "ash-Shamaliyah",
        "Sinnar"
    ],
    "Sri Lanka": [
        "Amparai",
        "Anuradhapuraya",
        "Badulla",
        "Boralesgamuwa",
        "Hambantota",
        "Kalatura",
        "Galla",
        "Kegalla",
        "Gampaha",
        "Kilinochchi",
        "Colombo",
        "Kurunegala",
        "Madakalpuwa",
        "Malwana",
        "Matale",
        "Matara",
        "Monaragala",
        "North Eastern Province",
        "Mullaitivu",
        "North Western Province",
        "Mannarama",
        "Maha Nuwara",
        "Nuwara Eliya",
        "Ratnapuraya",
        "Southern Province",
        "Tirikunamalaya",
        "Tuscany",
        "Vavuniyawa",
        "Western Province",
        "Yapanaya",
        "Puttalama",
        "kadawatha",
        "Polonnaruwa"
    ],
    "South Sudan": ["Central Equatoria"],
    Suriname: [
        "Brokopondo",
        "Commewijne",
        "Coronie",
        "Marowijne",
        "Nickerie",
        "Para",
        "Paramaribo",
        "Saramacca",
        "Wanica"
    ],
    "Svalbard And Jan Mayen Islands": ["Svalbard"],
    Swaziland: ["Hhohho", "Lubombo", "Shiselweni", "Manzini"],
    Seychelles: ["Anse Boileau", "Victoria", "Cascade", "Anse Royale", "Takamaka"],
    Sweden: [
        "Angermanland",
        "Alvsborgs Lan",
        "Blekinge",
        "Bohuslan",
        "Gaza",
        "Halland",
        "Gotland",
        "Jamtland",
        "Kalmar",
        "Jonkoping",
        "Kristianstads",
        "Kronoberg",
        "Norrbotten",
        "Orebro",
        "Ostergotland",
        "Skane",
        "Saltsjo-Boo",
        "Smaland",
        "Stockholm",
        "Sodermanland",
        "Uppsala",
        "Dalarna",
        "Gavleborg",
        "Varmland",
        "Vasterbotten",
        "Vastergotland",
        "Vasternorrland",
        "Vastmanland",
        "Vastra Gotaland"
    ],
    Switzerland: [
        "Appenzell Inner-Rhoden",
        "Appenzell-Ausser Rhoden",
        "Basel-Landschaft",
        "Basel-Stadt",
        "Bern",
        "Canton Ticino",
        "Glarus",
        "Graubunden",
        "Heerbrugg",
        "Jura",
        "Geneve",
        "Kanton Aargau",
        "Luzern",
        "Morbio Inferiore",
        "Muhen",
        "Neuchatel",
        "Fribourg",
        "Nidwalden",
        "Sankt Gallen",
        "Schaffhausen",
        "Obwalden",
        "Schwyz",
        "Solothurn",
        "Ticino",
        "Thurgau",
        "Aargau",
        "Uri",
        "Vauffelin",
        "Zug",
        "Zurich",
        "Vaud",
        "Valais"
    ],
    Syria: [
        "Aleppo",
        "Dar''a",
        "Dayr-az-Zawr",
        "Dimashq",
        "Halab",
        "Hims",
        "Hamah",
        "Idlib",
        "Madinat Dimashq",
        "Tartus",
        "al-Hasakah",
        "al-Ladhiqiyah",
        "ar-Raqqah",
        "al-Qunaytirah",
        "as-Suwayda"
    ],
    Taiwan: [
        "Changhua County",
        "Chiayi County",
        "Chiayi City",
        "Hsinchu City",
        "Taipei City",
        "Hsinchu County",
        "Kinmen County",
        "Keelung City",
        "Nantou County",
        "Miaoli County",
        "Penghu County",
        "Taichung City",
        "Pingtung County",
        "Hualien County",
        "Kaohsiung City",
        "Taoyuan City",
        "Yilan County",
        "YunLin County",
        "Lienchiang County",
        "New Taipei City",
        "Tainan City",
        "Taitung County"
    ],
    Togo: ["Centre", "Kara", "Maritime", "Plateaux", "Savanes"],
    Tokelau: ["Fakaofo", "Nukunonu", "Atafu"],
    Tonga: ["Eua", "Ha''apai", "Vava''u", "Niuas", "Tongatapu"],
    "Trinidad And Tobago": [
        "Caroni",
        "Arima-Tunapuna-Piarco",
        "Diego Martin",
        "Chaguanas",
        "Glencoe",
        "Couva-Tabaquite-Talparo",
        "Point Fortin",
        "Penal Debe",
        "Princes Town",
        "San Juan",
        "Port of Spain",
        "Sangre Grande",
        "Tobago",
        "Siparia",
        "Saint George",
        "San Fernando"
    ],
    Thailand: [
        "Amnat Charoen",
        "Ang Thong",
        "Bangkok",
        "Buri Ram",
        "Chachoengsao",
        "Chai Nat",
        "Changwat Chaiyaphum",
        "Chiang Rai",
        "Chiang Mai",
        "Chon Buri",
        "Chumphon",
        "Kalasin",
        "Kamphaeng Phet",
        "Kanchanaburi",
        "Chanthaburi",
        "Chaiyaphum",
        "Lampang",
        "Lamphun",
        "Loei",
        "Lop Buri",
        "Mae Hong Son",
        "Krung Thep",
        "Maha Sarakham",
        "Mukdahan",
        "Nakhon Nayok",
        "Nakhon Pathom",
        "Nakhon Phanom",
        "Nakhon Ratchasima",
        "Nakhon Sawan",
        "Nakhon Si Thammarat",
        "Nan",
        "Narathiwat",
        "Nong Bua Lam Phu",
        "Khon Kaen",
        "Krabi",
        "Nonthaburi",
        "Phangnga",
        "Phatthalung",
        "Pattani",
        "Phayao",
        "Phetchabun",
        "Phetchaburi",
        "Phichit",
        "Pathum Thani",
        "Phrae",
        "Phuket",
        "Phra Nakhon Si Ayutthaya",
        "Prachin Buri",
        "Prachuap Khiri Khan",
        "Ranong",
        "Rayong",
        "Ratchaburi",
        "Sa Kaeo",
        "Nong Khai",
        "Roi Et",
        "Phitsanulok",
        "Sakon Nakhon",
        "Samut Prakan",
        "Samut Sakhon",
        "Samut Songkhran",
        "Saraburi",
        "Si Sa Ket",
        "Sing Buri",
        "Satun",
        "Songkhla",
        "Sukhothai",
        "Suphan Buri",
        "Surat Thani",
        "Surin",
        "Trang",
        "Udon Thani",
        "Uthai Thani",
        "Ubon Ratchathani",
        "Uttaradit",
        "Yala",
        "Yasothon",
        "Trat",
        "Tak"
    ],
    Tunisia: [
        "Aryanah",
        "Bin ''Arus",
        "Binzart",
        "Gouvernorat de Ariana",
        "Gouvernorat de Nabeul",
        "Gouvernorat de Sousse",
        "Hammamet Yasmine",
        "Jundubah",
        "Manubah",
        "Madaniyin",
        "Monastir",
        "Nabul",
        "Qabis",
        "Bajah",
        "Qafsah",
        "Qibili",
        "Safaqis",
        "Sfax",
        "Sidi Bu Zayd",
        "Silyanah",
        "Susah",
        "Tatawin",
        "al-Kaf",
        "Zaghwan",
        "al-Mahdiyah",
        "Tawzar",
        "al-Qayrawan",
        "al-Qasrayn",
        "al-Munastir",
        "Tunis"
    ],
    Tajikistan: ["Dushanbe", "Gorno-Badakhshan", "Karotegin", "Khatlon", "Sughd"],
    Tanzania: [
        "Dodoma",
        "Arusha",
        "Dar es Salaam",
        "Kilimanjaro",
        "Lindi",
        "Mara",
        "Mbeya",
        "Morogoro",
        "Mtwara",
        "Mwanza",
        "Kagera",
        "Kigoma",
        "Pwani",
        "Rukwa",
        "Singida",
        "Ruvuma",
        "Shinyanga",
        "Iringa",
        "Tanga",
        "Tabora",
        "Zanzibar and Pemba"
    ],
    Turkey: [
        "Adana",
        "Adiyaman",
        "Afyon",
        "Agri",
        "Aksaray",
        "Ankara",
        "Ardahan",
        "Artvin",
        "Aydin",
        "Balikesir",
        "Batman",
        "Bartin",
        "Amasya",
        "Bayburt",
        "Antalya",
        "Bilecik",
        "Bingol",
        "Bitlis",
        "Bolu",
        "Bursa",
        "Cankiri",
        "Corum",
        "Denizli",
        "Diyarbakir",
        "Duzce",
        "Edirne",
        "Canakkale",
        "Erzurum",
        "Eskisehir",
        "Gaziantep",
        "Erzincan",
        "Giresun",
        "Gumushane",
        "Hakkari",
        "Hatay",
        "Icel",
        "Igdir",
        "Isparta",
        "Istanbul",
        "Izmir",
        "Kahramanmaras",
        "Karabuk",
        "Karaman",
        "Kars",
        "Karsiyaka",
        "Burdur",
        "Kayseri",
        "Kastamonu",
        "Kilis",
        "Kirikkale",
        "Kirklareli",
        "Elazig",
        "Kutahya",
        "Lefkosa",
        "Malatya",
        "Manisa",
        "Mardin",
        "Mugla",
        "Mus",
        "Nevsehir",
        "Nigde",
        "Ordu",
        "Osmaniye",
        "Rize",
        "Sakarya",
        "Konya",
        "Kocaeli",
        "Samsun",
        "Sanliurfa",
        "Sinop",
        "Siirt",
        "Sirnak",
        "Kirsehir",
        "Sivas",
        "Tekirdag",
        "Tokat",
        "Trabzon",
        "Van",
        "Yalova",
        "Yozgat",
        "Zonguldak",
        "Usak",
        "Tunceli"
    ],
    Tuvalu: ["Funafuti", "Nanumanga", "Nanumea", "Niutao", "Nui", "Nukufetau", "Vaitupu", "Nukulaelae"],
    Uganda: ["Eastern", "Northern", "Western", "Central"],
    "Turks And Caicos Islands": ["Grand Turk", "South Caicos and East Caicos"],
    Ukraine: [
        "Cherkas''ka",
        "Chernihivs''ka",
        "Chernivets''ka",
        "Crimea",
        "Dnipropetrovska",
        "Donets''ka",
        "Kharkiv",
        "Ivano-Frankivs''ka",
        "Kharkov",
        "Khersonska",
        "Khmel''nyts''ka",
        "Kyyivs''ka",
        "L''vivs''ka",
        "Luhans''ka",
        "Mykolayivs''ka",
        "Odes''ka",
        "Odessa",
        "Poltavs''ka",
        "Kyyiv",
        "Rivnens''ka",
        "Sums''ka",
        "Sevastopol",
        "Ternopil''s''ka",
        "Kirovohrad",
        "Zakarpats''ka",
        "Vynnyts''ka",
        "Zhytomyrs''ka",
        "Zaporizhia",
        "Volyns''ka",
        "Krym"
    ],
    "United Kingdom": [
        "Aberdeen",
        "Aberdeenshire",
        "Argyll",
        "Berkshire",
        "Birmingham",
        "Brechin",
        "Bridgnorth",
        "Armagh",
        "Bristol",
        "Bedfordshire",
        "Belfast",
        "Cambridge",
        "Buckinghamshire",
        "Cambridgeshire",
        "Channel Islands",
        "Conwy",
        "Cornwall",
        "Coventry",
        "Craven Arms",
        "Cumbria",
        "Denbighshire",
        "Derby",
        "Derbyshire",
        "Cleveland",
        "Co Fermanagh",
        "Devon",
        "Didcot",
        "Dial Code Dungannon",
        "Dorset",
        "Cheshire",
        "Dunbartonshire",
        "Durham",
        "East Sussex",
        "East Midlands",
        "East Yorkshire",
        "England",
        "Essex",
        "Fife",
        "Fermanagh",
        "Flintshire",
        "Fulham",
        "East Dunbartonshire",
        "East Lothian",
        "Gainsborough",
        "Hants",
        "Herefordshire",
        "Hertfordshire",
        "Ireland",
        "Isle Of Man",
        "Isle of Wight",
        "Kenford",
        "Kent",
        "Kilmarnock",
        "Glocestershire",
        "Hampshire",
        "Gwent",
        "Lanarkshire",
        "Lancashire",
        "Leicestershire",
        "Llanymynech",
        "Lincolnshire",
        "Ludlow",
        "Merseyside",
        "Mayfair",
        "Mid Glamorgan",
        "Middlesex",
        "Mildenhall",
        "Monmouthshire",
        "Manchester",
        "North Humberside",
        "London",
        "North Yorkshire",
        "Northamptonshire",
        "Northants",
        "Northern Ireland",
        "Northumberland",
        "Norfolk",
        "Newton Stewart",
        "Nottinghamshire",
        "SUSSEX",
        "Sark",
        "Roos-shire",
        "Scotland",
        "Scottish Borders",
        "Shropshire",
        "Somerset",
        "Oxford",
        "Powys",
        "South Glamorgan",
        "Southwell",
        "Staffordshire",
        "Strabane",
        "Suffolk",
        "Sussex",
        "Surrey",
        "South Wales",
        "Tyne and Wear",
        "Utah",
        "Tyrone",
        "Wales",
        "Warwickshire",
        "West Lothian",
        "Twickenham",
        "West Yorkshire",
        "Whissendine",
        "Wiltshire",
        "Wokingham",
        "West Sussex",
        "Worcestershire",
        "Wrexham",
        "Wurttemberg",
        "Yorkshire",
        "West Midlands",
        "South Yorkshire"
    ],
    "United Arab Emirates": [
        "Abu Zabi",
        "Dubai",
        "Ajman",
        "Umm al Qaywayn",
        "al-Fujayrah",
        "ash-Shariqah",
        "Ras al-Khaymah",
        "Sharjah",
        "Sharjha"
    ],
    "United States Minor Outlying Islands": ["United States Minor Outlying I"],
    "United States": [
        "Alabama",
        "Byram",
        "California",
        "Cokato",
        "Arizona",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Alaska",
        "Arkansas",
        "District of Columbia",
        "Idaho",
        "Illinois",
        "Hawaii",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Florida",
        "Georgia",
        "Massachusetts",
        "Medfield",
        "Michigan",
        "Maryland",
        "Mississippi",
        "Minnesota",
        "Lowa",
        "Missouri",
        "Montana",
        "Maine",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Jersy",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Ontario",
        "Oregon",
        "Pennsylvania",
        "New York",
        "North Carolina",
        "New Mexico",
        "Ramey",
        "Rhode Island",
        "Tennessee",
        "Texas",
        "Trimble",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "South Carolina",
        "Wyoming",
        "South Dakota",
        "Sublimity"
    ],
    Turkmenistan: ["Ahal", "Asgabat", "Balkan", "Dasoguz", "Lebap", "Mari"],
    "Vatican City State (Holy See)": ["Vatican City State (Holy See)"],
    Venezuela: [
        "Amazonas",
        "Anzoategui",
        "Apure",
        "Aragua",
        "Barinas",
        "Cojedes",
        "Bolivar",
        "Delta Amacuro",
        "Distrito Federal",
        "Falcon",
        "Guarico",
        "Lara",
        "Merida",
        "Miranda",
        "Monagas",
        "Nueva Esparta",
        "Portuguesa",
        "Carabobo",
        "Sucre",
        "Tachira",
        "Trujillo",
        "Vargas",
        "Yaracuy",
        "Zulia"
    ],
    Vietnam: [
        "Bac Giang",
        "Dong Bang Song Cuu Long",
        "Binh Dinh",
        "Dong Bang Song Hong",
        "Binh Duong",
        "Dong Nai",
        "Da Nang",
        "Dong Nam Bo",
        "Duyen Hai Mien Trung",
        "Hanoi",
        "Mien Nui Va Trung Du",
        "Thai Nguyen",
        "Thanh Pho Ho Chi Minh",
        "Thu Do Ha Noi",
        "Khu Bon Cu",
        "Tinh Can Tho",
        "Hung Yen",
        "Tinh Da Nang",
        "Long An",
        "Tinh Gia Lai"
    ],
    "Virgin Islands (British)": ["Anegada", "Jost van Dyke", "Tortola"],
    "Virgin Islands (US)": ["Saint John", "Saint Thomas", "Saint Croix"],
    "Wallis And Futuna Islands": ["Alo", "Singave", "Wallis"],
    Vanuatu: ["Malampa", "Penama", "Sanma", "Shefa", "Tafea", "Torba"],
    Uzbekistan: [
        "Andijon",
        "Fargona",
        "Horazm",
        "Cizah",
        "Kaskadar",
        "Korakalpogiston",
        "Namangan",
        "Navoi",
        "Buhoro",
        "Surhondar",
        "Toskent",
        "Sirdare",
        "Samarkand",
        "Buxoro Viloyati"
    ],
    Yemen: [
        "Adan",
        "Abyan",
        "Dhamar",
        "Hadramaut",
        "Hajjah",
        "Hudaydah",
        "Ibb",
        "Lahij",
        "Ma''rib",
        "Madinat San''a",
        "Sa''dah",
        "Sana",
        "Shabwah",
        "Ta''izz",
        "al-Bayda",
        "al-Hudaydah",
        "al-Jawf",
        "al-Mahwit",
        "al-Mahrah"
    ],
    Yugoslavia: ["Central Serbia", "Kosovo and Metohija", "Montenegro", "Republic of Serbia", "Serbia", "Vojvodina"],
    Zambia: [
        "Eastern",
        "Luapala",
        "Copperbelt",
        "Lusaka",
        "North-Western",
        "Northern",
        "Southern",
        "Western",
        "Central"
    ],
    Zimbabwe: [
        "Mashonaland Central",
        "Mashonaland East",
        "Harare",
        "Mashonaland West",
        "Masvingo",
        "Matabeleland North",
        "Matabeleland South",
        "Manicaland",
        "Bulawayo",
        "Midlands"
    ],
    "Western Sahara": ["Bu Jaydur", "Wad-adh-Dhahab", "al-''Ayun", "as-Samarah"],
    Uruguay: [
        "Artigas",
        "Canelones",
        "Colonia",
        "Cerro Largo",
        "Durazno",
        "Flores",
        "FLorida",
        "Lavalleja",
        "Maldonado",
        "Montevideo",
        "Paysandu",
        "Rivera",
        "Rocha",
        "Salto",
        "Rio Negro",
        "San Jose",
        "Soriano",
        "Tacuarembo",
        "Treinta y Tres"
    ]
};
