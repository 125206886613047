import { ButtonProps } from "antd";
import { Colors } from "../../constants";

export enum ButtonType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    DANGER = "danger"
}

export enum ButtonSize {
    LARGE = "large",
    SMALL = "small"
}

export interface ITAButtonProps extends ButtonProps {
    btnType?: ButtonType;
    btnSize?: ButtonSize;
    loading?: boolean;
    width?: string;
    colorTheme?: string;
    textColor?: string;
}

interface IButtonSizesMapper {
    [large: string]: { width: string; height: string };
    small: { width: string; height: string };
}

export const buttonSizesMapper: IButtonSizesMapper = {
    large: {
        width: "124px",
        height: "40px"
    },

    small: {
        width: "115px",
        height: "40px"
    }
};

interface IButtonStyles {
    backgroundColor: string;
    borderColor: string;
    text: {
        color: string;
        size: string;
        lineHeight: string;
    };
}

interface IButtonStates {
    default: IButtonStyles;
    hover: IButtonStyles;
    pressed: IButtonStyles;
    // disabled: IButtonStyles;
}

interface IButtonTypesMapper {
    [primary: string]: IButtonStates;
    secondary: IButtonStates;
}

export const buttonTypesMapper: IButtonTypesMapper = {
    primary: {
        default: {
            backgroundColor: Colors.TA_WHITE,
            borderColor: Colors.TA_WHITE,
            text: {
                color: Colors.TA_BLACK,
                size: "14px",
                lineHeight: "18px"
            }
        },

        hover: {
            backgroundColor: Colors.TA_WHITE,
            borderColor: Colors.TA_WHITE,
            text: {
                color: Colors.TA_BLACK,
                size: "14px",
                lineHeight: "18px"
            }
        },
        pressed: {
            backgroundColor: Colors.TA_WHITE,
            borderColor: Colors.TA_WHITE,
            text: {
                color: Colors.TA_BLACK,
                size: "14px",
                lineHeight: "18px"
            }
        }
    },
    secondary: {
        default: {
            backgroundColor: "transparent",
            borderColor: Colors.TA_WHITE,
            text: {
                color: Colors.TA_WHITE,
                size: "14px",
                lineHeight: "20px"
            }
        },
        hover: {
            backgroundColor: Colors.TA_BLACK,
            borderColor: Colors.TA_BLACK,
            text: {
                color: Colors.TA_WHITE,
                size: "14px",
                lineHeight: "20px"
            }
        },
        pressed: {
            backgroundColor: "transparent",
            borderColor: Colors.TA_GREEN,
            text: {
                color: Colors.TA_GREEN,
                size: "14px",
                lineHeight: "20px"
            }
        }
    }
};
