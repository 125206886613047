import styled from "styled-components";
import { Button } from "antd";
import { ButtonSize, buttonSizesMapper, ButtonType, buttonTypesMapper, ITAButtonProps } from "../index";

const DEFAULT_BTN_SIZE = ButtonSize.LARGE;
const DEFAULT_BTN_TYPE = ButtonType.PRIMARY;

export const TAButton = styled(Button)<ITAButtonProps>`
    height: ${({ btnSize = DEFAULT_BTN_SIZE }) => buttonSizesMapper[btnSize].height};
    width: ${({ width = "auto" }) => width};
    outline: none;
    display: flex;
    align-items: center;
    border-radius: 0;
    padding 0 10px;
    justify-content: center;
    background-color: ${({ btnType = DEFAULT_BTN_TYPE, colorTheme }) =>
        colorTheme || buttonTypesMapper[btnType].default.backgroundColor};
    border: ${({ btnType = DEFAULT_BTN_TYPE, colorTheme }) =>
        `1px solid ${colorTheme || buttonTypesMapper[btnType].default.borderColor}`};
    font-size: ${({ btnType = DEFAULT_BTN_TYPE }) => buttonTypesMapper[btnType].default.text.size};
    font-weight: 500;
    color: ${({ btnType = DEFAULT_BTN_TYPE, textColor }) => textColor ?? buttonTypesMapper[btnType].default.text.color};
    line-height: ${({ btnType = DEFAULT_BTN_TYPE }) => buttonTypesMapper[btnType].default.text.lineHeight};

    transition: background-color 0.2s;
  

    &:hover,
    &:focus {
        background-color: ${({ btnType = DEFAULT_BTN_TYPE, colorTheme }) =>
            colorTheme || buttonTypesMapper[btnType].hover.backgroundColor};
        border: ${({ btnType = DEFAULT_BTN_TYPE, colorTheme }) =>
            `1px solid ${colorTheme || buttonTypesMapper[btnType].hover.borderColor}`};
        font-size: ${({ btnType = DEFAULT_BTN_TYPE }) => buttonTypesMapper[btnType].hover.text.size};
        color: ${({ btnType = DEFAULT_BTN_TYPE }) => buttonTypesMapper[btnType].hover.text.color};
        line-height: ${({ btnType = DEFAULT_BTN_TYPE }) => buttonTypesMapper[btnType].hover.text.lineHeight};
    }

    &:active {
        background-color: ${({ btnType = DEFAULT_BTN_TYPE, colorTheme }) =>
            colorTheme || buttonTypesMapper[btnType].pressed.backgroundColor};
        border: ${({ btnType = DEFAULT_BTN_TYPE, colorTheme }) =>
            `1px solid ${colorTheme || buttonTypesMapper[btnType].pressed.borderColor}`};
        font-size: ${({ btnType = DEFAULT_BTN_TYPE }) => buttonTypesMapper[btnType].pressed.text.size};
        color: ${({ btnType = DEFAULT_BTN_TYPE }) => buttonTypesMapper[btnType].pressed.text.color};
        line-height: ${({ btnType = DEFAULT_BTN_TYPE }) => buttonTypesMapper[btnType].pressed.text.lineHeight};
    }

    &:disabled,
	&:disabled:hover {
		background-color: #999;
		border: none;
        color:#fff;
	}

    
`;
