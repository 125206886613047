import { styled } from "styled-components";
import { ScreenSize, screenWidths } from "../../../hooks";

export const CTASectionContainer = styled.section`
    background-color: #000;
    width: 100%;
    margin: 0;
    position: relative;
    padding: 180px 0px 60px 20px;

    @media (min-width: ${screenWidths[ScreenSize.XS]}) {
        padding: 60px 0px 60px 5px;
    }

    @media (min-width: ${screenWidths[ScreenSize.MD]}) {
        font-size: 2rem;
        width: 90%;
        margin-left: 5%;
        margin-bottom: 100px;
        padding: 60px 0px 60px 50px;
        min-height: 500px;
    }
`;
