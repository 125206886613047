import { Dropdown, Menu, Space } from "antd";
import { HTMLAttributes, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { ButtonType, Navbar, TAButton } from "../components";
import SideDrawer from "../components/sidebar/side-drawer/side-drawer";
import { useMedia } from "../hooks/media.hook";
import configProxy from "../utils/config";
import { AiOutlineDown } from "react-icons/ai";

interface ITASidebarProps {
    header?: Element | ((collapsed: boolean, visible: boolean) => any);
    selected: string;
    menuItems: any;
    visible: boolean;
    close: () => any;
    collapseOnRouteChange: boolean;
}

export const TASideBar: React.FC<HTMLAttributes<HTMLBaseElement> & ITASidebarProps> = ({
    header,
    selected,
    menuItems,
    visible,
    close,
    collapseOnRouteChange
}) => {
    let [collapsed, setCollapsed] = useState(true);
    let media = configProxy.defaultExpandScreenSize;
    const routeListner = () => {
        if (collapseOnRouteChange) close();
    };

    useEffect(() => {
        routeListner();
    }, [useLocation().pathname]);

    let desktopView = useMedia(media);

    const collapseHandler = (collapseState: boolean) => {
        setCollapsed(collapseState);
    };

    let headerComp = header ? (typeof header === "function" ? header(collapsed, visible) : header) : null;
    let sidebar = desktopView ? null : (
        <SideDrawer
            visible={visible}
            onClose={close}
            collapsable={desktopView}
            onCollapse={collapseHandler}
            collapsed={desktopView && collapsed}
            media={media}
        >
            {headerComp}

            <Menu
                selectedKeys={[selected]}
                inlineCollapsed={desktopView && collapsed}
                mode="inline"
                theme="dark"
                style={{ backgroundColor: "transparent" }}
                className="mt-4"
            >
                <Menu.Item onClick={close}>
                    <a href="#exhibitors">Exhibitors</a>
                </Menu.Item>
                <Menu.Item onClick={close} className="mb-5">
                    <a href="#speakers">Speakers</a>
                </Menu.Item>

                <Menu.Item onClick={close} className="mb-3">
                    <div className="d-flex align-items-center">
                        <a
                            href="https://www.youtube.com/playlist?list=PLPdJSS01sOFwHIvXTIx9m6lx5hNm4Spkf"
                            target="_blank"
                        >
                            <TAButton btnType={ButtonType.SECONDARY}>Explore Our Sessions</TAButton>
                        </a>
                    </div>
                </Menu.Item>
                <Menu.Item onClick={close}>
                    <div className="d-flex align-items-center">
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeaUsvbJwjwtF38amWC_xrdUNd2sI6NVTrskDsuDCg8FkbLNQ/viewform"
                            target="_blank"
                        >
                            <TAButton btnType={ButtonType.PRIMARY} style={{ color: "#000" }} colorTheme="#fff">
                                Become a Speaker
                            </TAButton>
                        </a>
                    </div>
                </Menu.Item>
                {/* <Menu.SubMenu title="Join As">
                    <Menu.Item key="1">
                        <a href="https://forms.gle/eQ3TdBkvExBRgpWh9" target="_blank">
                            Partner
                        </a>
                    </Menu.Item>
                </Menu.SubMenu> */}

                <Menu.Item onClick={close} className="mt-4">
                    <a href="#exhibitor-request">
                        <TAButton btnType={ButtonType.PRIMARY} style={{ color: "#000" }} colorTheme="#fff">
                            Become an Exhibitor
                        </TAButton>
                    </a>
                </Menu.Item>
            </Menu>
        </SideDrawer>
    );
    return desktopView ? sidebar : ReactDOM.createPortal(sidebar, document.getElementById("root") as Element);
};
