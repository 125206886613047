import { HTMLAttributes } from "react";
const talentsArenaLogoWhite = require("../../assets/images/jobstack-logo.png");

export const JobStackLogo: React.FC<HTMLAttributes<HTMLDivElement> & { width?: string }> = ({
    width = "150px",
    ...restProps
}) => {
    return (
        <div {...restProps}>
            <img loading="lazy" style={{ width }} src={talentsArenaLogoWhite} alt="Talents arena logo" />
        </div>
    );
};
